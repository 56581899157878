import * as React from 'react';
import {useState} from 'react';
import {CustomTableComponent, TableIProps} from "./CustomTableComponent";
import {TableCustom} from "./Table";
import {DEFAULT_SIZE, filterDataV2} from "../../../utils";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Reducers";
import styled from "styled-components";
import useTranslate from "../../../hooks/useTranslate";

const SearchContainer = styled.div`
    width: 300px;
    @media (max-width: 1000px) {
        width: 100%
    }
`

const CustomOptimizeTableComponent = (props: TableIProps & { getTableData?: (data: any[]) => void }) => {
    const {page, text} = useSelector((state: RootState) => state.SearchReducer);
    const [search, setSearch] = useState("")
    const {t} = useTranslate("common");
    const [tableCustom, setTableCustom] = useState<TableCustom>(new TableCustom({
        data: [],
        columns: [],
        pageSize: DEFAULT_SIZE,
        currentPage: 1
    }))

    const [tableData, setTableData] = useState<{ data: any[], totalData: number }>({
        data: [], totalData: 0
    })

    React.useEffect(() => {
        const table = new TableCustom({
            data: props.data,
            columns: props.columns,
            pageSize: 15,
            currentPage: props.currentPage ? props.currentPage : 1
        });

        setTableData({
            data: table.getTableDataByPage(),
            totalData: table.getTableData().length
        })
        setTableCustom(table)

    }, [props]);

    React.useEffect(() => {

        const searchTable = text.length > 0 ? text : search;

        if (searchTable.length > 0) {
            if (props.localFilterV2){

                tableCustom.setTableData(props.data.filter(item => {
                    return searchTable.split(" ").every(term => {
                        return JSON.stringify(item).toLowerCase().includes(term.toLowerCase())
                    })
                }))
            }else{
                tableCustom.setTableData(filterDataV2(props.data, text));
            }
        } else {
            tableCustom.setTableData(props.data);
        }

        tableCustom.setCurrentPage(1);

        setTableData({
            data: tableCustom.getTableDataByPage(),
            totalData: tableCustom.getTableData().length
        })
    }, [page, text, props, search]);

    React.useEffect(() => {
        if (props.getTableData) {
            props.getTableData(tableCustom.getTableData())
        }
    }, [tableData.data]);

    return (
        <div>
            {props.localFilterV2 && <SearchContainer className="form-search mb-2">

                <input
                    className="form-control"
                    placeholder={t("search").concat("...")}
                    type="search"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                />

            </SearchContainer>}
            <CustomTableComponent {...props}
                                  data={tableData.data}
                                  currentPage={tableCustom.getCurrentPage()}
                                  totalData={tableData.totalData} tableData={tableCustom}
                                  onChangePage={(page) => {
                                      tableCustom.setCurrentPage(page);

                                      setTableData({
                                          data: tableCustom.getTableDataByPage(),
                                          totalData: tableCustom.getTableData().length
                                      })
                                  }} onShowSizeChange={(page, size) => {

                tableCustom.setCurrentPage(1);
                tableCustom.setPageSize(size);
                setTableData({
                    data: tableCustom.getTableDataByPage(),
                    totalData: tableCustom.getTableData().length
                })
            }}/>
        </div>
    );
};

export default CustomOptimizeTableComponent