// RequestQueue.ts
export class RequestQueue {
    private maxConcurrent: number;
    private currentConcurrent: number;
    private queue: Array<any>;
    private requestMap: Map<string, any>;

    constructor(maxConcurrent = 2) {
        this.maxConcurrent = maxConcurrent;
        this.currentConcurrent = 0;
        this.queue = [];
        this.requestMap = new Map();
    }

    addRequest(id: string, requestFn: () => Promise<any>): Promise<any> {
        if (this.requestMap.has(id)) {
            // La petición ya está en progreso o en cola
            return this.requestMap.get(id).promise;
        }

        let resolve: any, reject: any;
        const promise = new Promise((res, rej) => { resolve = res; reject = rej; });
        const request = {
            id,
            requestFn,
            resolve,
            reject,
            promise,
        };
        this.queue.push(request);
        this.requestMap.set(id, request);
        this.runNext();
        return promise;
    }

    hasPendingRequests() {
        return this.queue.length > 0;
    }



    private runNext() {
        if (this.currentConcurrent >= this.maxConcurrent) {
            return;
        }
        if (this.queue.length === 0) {
            return;
        }
        const nextRequest = this.queue.shift();
        this.currentConcurrent++;
        nextRequest.requestFn()
            .then((result: any) => {
                this.currentConcurrent--;
                nextRequest.resolve(result);
                this.requestMap.delete(nextRequest.id);
                this.runNext();
            })
            .catch((error: any) => {
                this.currentConcurrent--;
                nextRequest.reject(error);
                this.requestMap.delete(nextRequest.id);
                this.runNext();
            });
    }
}
