import * as React from "react";
import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChatBotHeaderComponent from "./ChatBotHeaderComponent";
import ChatBotBodyComponent from "./ChatBotBodyComponent";
import ChatBotInputComponent from "./ChatBotInputComponent";

import Image from "next/image";

export const chatbot = `https://demo.sbx360.ai/sbx-server/www/fempha/ai-agent/images/bot_icon.png`;
type Props = {
  chatMessages: ChatMessages[];
  setChatMessages: (message: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  toggle: () => void;
  helperButton?: ReactElement;
  visibleChat: boolean;
  initialLoading: boolean;
};

export type ChatMessages = {
  user: string;
  message: string | ReactElement;
  createdAt?: Date;
  messageClassname?: string;
  messageStyle?: React.CSSProperties;
};

const ChatBotComponent = ({
  chatMessages,
  setChatMessages,
  loading,
  initialLoading,
  visibleChat,
  toggle,
  helperButton,
  setLoading,
}: Props) => {
  return (
    <div className="position-fixed" style={{ right: 16, bottom: 20 }}>
      <div className="d-flex flex-column align-items-end position-relative">
        {visibleChat && (
          <div
            className="bg-white border rounded shadow me-4 mb-3"
            style={{ width: "40em", minHeight: "30vh" }}
          >
            <ChatBotHeaderComponent helperButton={helperButton} />

            <ChatBotBodyComponent
              initialLoading={initialLoading}
              chatMessages={chatMessages}
            />

            <ChatBotInputComponent
              disabled={initialLoading}
              setLoading={setLoading}
              loading={loading}
              submit={(message) => {
                setChatMessages(message);
              }}
            />
          </div>
        )}
        <div
          onClick={toggle}
          className="bg-white shadow-lg pointer p-2 rounded-circle d-flex justify-content-center align-items-center"
          style={{ width: "50px", height: "50px" }}
        >
          {visibleChat ? (
            <FontAwesomeIcon icon={faChevronDown} color={"blue"} />
          ) : (
            <Image
              priority
              src={chatbot}
              height={38}
              width={35}
              alt="Follow us on Twitter"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBotComponent;
