import * as React from "react";
import { convertDateToYYYYMMDDHHmm } from "../../../utils";
import Image from "next/image";
import useTranslate from "../../../hooks/useTranslate";
import { chatbot, ChatMessages } from "./ChatBotComponent";

type Props = {
  chatMessages: ChatMessages[];
  initialLoading: boolean;
};

const ChatMessageComponent = ({
  chatMessage,
}: {
  chatMessage: ChatMessages;
  messageClassname?: string;
  messageStyle?: React.CSSProperties;
}) => {
  return (
    <div
      className={`d-flex ${
        chatMessage.user === "bot"
          ? "flex-column w-85 position-relative"
          : "justify-content-end ms-5"
      }  gap-2`}
    >
      {chatMessage.user === "bot" && (
        <div
          className="position-absolute"
          style={{
            top: -12,
            left: -7,
          }}
        >
          <Image
            priority
            src={chatbot}
            height={30}
            width={27}
            alt="Follow us on Twitter"
          />
        </div>
      )}

      <div
        className="d-flex flex-column w-100 overflow-hidden"
        style={{ marginLeft: "15px" }}
      >
        <span
          style={
            chatMessage.messageStyle ?? {
              fontSize: "14px",
              borderRadius: "12px",
              overflowWrap: "break-word",
              display: "inline-block",
            }
          }
          className={`shadow border p-3 rounded-3 ${
            chatMessage.messageClassname ?? ""
          }  ${
            chatMessage.user === "bot"
              ? "bg-white text-black"
              : " bg-info text-white"
          }`}
        >
          {chatMessage.message}
        </span>

        {chatMessage.createdAt && (
          <span
            className={`text-gray ${
              chatMessage.user === "bot" ? "" : "text-end"
            } mt-2`}
            style={{ fontSize: "10px" }}
          >
            {convertDateToYYYYMMDDHHmm(chatMessage.createdAt)}
          </span>
        )}
      </div>
    </div>
  );
};

const ChatBotBodyComponent = ({ chatMessages, initialLoading }: Props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslate("report");
  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
    }
  }, [chatMessages]);

  const getInitialMessage = () => {
    if (initialLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <ChatMessageComponent
            chatMessage={{ user: "bot", message: t("initializing_your_data") }}
          />
          <div className="dot-carousel mx-5"></div>
        </div>
      );
    }

    if (chatMessages.length === 0) {
      return (
        <div className="d-flex flex-column gap-3">
          <ChatMessageComponent
            chatMessage={{
              user: "bot",
              message: t("how_can_i_help_you_today"),
            }}
          />
          <ChatMessageComponent
            chatMessage={{ user: "bot", message: t("init_bot_message") }}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className="d-flex flex-column overflow-auto gap-4 px-3 py-5"
      ref={containerRef}
      style={{ minHeight: "40vh", maxHeight: "50vh" }}
    >
      {getInitialMessage()}

      {chatMessages.map((chatMessage, index) => {
        return (
          <ChatMessageComponent
            chatMessage={chatMessage}
            key={`${chatMessage.user}_${index}`}
          />
        );
      })}
    </div>
  );
};

export default ChatBotBodyComponent;
