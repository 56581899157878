import { State } from "../../types/State";
import MultiSelectComponent from "./FieldComponents/MultiSelectComponent";
import React, { useContext, useEffect } from "react";
import { ValueType } from "react-select";
import { CalendarContext } from "../CalendarComponent/CalendarComponent";
import { findByModel } from "../../services/backend/SbxService";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Reducers";

interface IProps {
  id: string;
  required?: boolean;
  name: string;
  value?: ValueType<any, any>;
  invalid?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  multi?: boolean;
  onChange?: (value: ValueType<any, any> | ValueType<any, any>[]) => void;
}

const InviteSelectorComponent = (props: IProps) => {
  const {
    state: { event, sales },
  } = useContext(CalendarContext);

  const {
    user: { config },
  } = useSelector((root: RootState) => root.AuthReducer);

  const [loading, setLoading] = React.useState<State>(State.IDLE);
  const [options, setOptions] = React.useState<
    { label: string; value: string; data: any }[]
  >([]);

  const findUsers = (val: string, field: string) => {
    setLoading(State.PENDING);
    findByModel({
      row_model: "sbx_crm_user",
      where: [
        {
          ANDOR: "AND",
          GROUP: [
            {
              ANDOR: "AND",
              VAL: val,
              FIELD: field,
              OP: "=",
            },
          ],
        },
      ],
    })
      .then((response) => {
        setOptions(
          response.items?.map((item: any) => ({
            value: item.email,
            label: item.first_name,
            data: item,
          })),
        );
        setLoading(State.RESOLVED);
      })
      .catch(() => {
        setLoading(State.REJECTED);
      });
  };

  useEffect(() => {
    const {
      invites: {
        sales_addressee_field = "acc_maager",
        sbx_crm_user_field = "email",
      } = {},
    } = (config?.sbx_crm.accounts?.tabs_detail_config?.calendar as any) ?? {};

    const salesAddressee = sales.find(
      (s) => s._KEY === event?.data?.sales_addressee,
    );

    if (!salesAddressee?.[sales_addressee_field]) {
      setLoading(State.RESOLVED);
      setOptions([]);
    } else {
      findUsers(salesAddressee[sales_addressee_field], sbx_crm_user_field);
    }
  }, [event?.data.sales_addressee]);

  return (
    <>
      <MultiSelectComponent
        {...props}
        disabled={loading === State.PENDING}
        loading={loading === State.PENDING}
        options={options}
      />
    </>
  );
};

export default InviteSelectorComponent;
