import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { AuthActions, AuthStates } from "./Types";
import {
  changePasswordWithCodeService,
  forgotPasswordService,
  generateLinkToUserService,
  login,
  loginAs,
  loginWithLinkService,
  logoutService,
  me,
  mfaSendCodeService,
  verifyCodeService,
  verifyCodeToActivateService,
} from "../../services/backend/AuthService";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  error,
  permissionListToMap,
  setUserSentry,
  success,
  toast,
} from "../../utils";
import { actionsAuth } from "./Slice";
import { setHeaderToken } from "../../network";
import { Response } from "../../types/Response";
import { UserData } from "../../types/User";
import { actionsConfig } from "../Config/Slice";
import { TrackEventMonitor } from "../../classes/TrackEvent";
import { authReducer } from "../Selectors";
import session from "../../types/Session";

function setTrackEvent(res: Response<UserData>) {
  if (res?.item) {
    TrackEventMonitor.setMetadataExtra({
      user_email: res.item.email,
      user_id: res.item.id ?? res.item.user_id ?? null,
    });

    setHeaderToken(res.item.token ?? "");
    setUserSentry(res.item);
  }
}

function* loginSaga({
  payload,
}: PayloadAction<{ username: string; password: string }>) {
  try {
    const res: Response<UserData> = yield call(login, payload);

    if (res?.success && res.item) {
      if (res.item.verification) {
        yield put(actionsAuth.changeState(AuthStates.MFA_VERIFICATION));
      } else {
        setTrackEvent(res);
        let path;
        if (window.location.search.includes("?")) {
          path = window.location.search.replace("?", "");
        }
        yield put(
          actionsAuth.setUser({
            user: res.item,
            state: AuthStates.AUTHENTICATED,
            path,
          }),
        );

        if (res?.item?.config?.sbx_crm?.utils?.get_business_days) {
          yield put(actionsConfig.getBusinessDays());
        }
      }
    } else {
      if (res.item?.recover_password_expired) {
        yield put(actionsAuth.changeState(AuthStates.USER_PASSWORD_EXPIRED));
        toast({
          type: "error",
          message: res.item.message || "No se pudo iniciar sessión",
        });
      } else {
        throw Error(res.message);
      }
    }
  } catch (e: any) {
    yield put(actionsAuth.changeState(AuthStates.AUTHENTICATION_FAILED));
    toast({
      type: "error",
      message: e.message || "No se pudo iniciar sessión",
    });
  }
}

function* loginWithLinkSaga({ payload }: PayloadAction<string>) {
  try {
    const res: Response<UserData> = yield call(loginWithLinkService, payload);
    if (res.success && res.item) {
      if (res.item.verification) {
        yield put(actionsAuth.changeState(AuthStates.MFA_VERIFICATION));
      } else {
        setTrackEvent(res);
        yield put(
          actionsAuth.setUser({
            user: res.item,
            state: AuthStates.AUTHENTICATED,
          }),
        );
        if (res?.item?.config?.sbx_crm?.utils?.get_business_days) {
          yield put(actionsConfig.getBusinessDays());
        }
      }
    } else {
      if (res.item?.recover_password_expired) {
        yield put(actionsAuth.changeState(AuthStates.USER_PASSWORD_EXPIRED));
        throw Error("Password expired, please change the password!");
      } else {
        throw Error(res.message);
      }
    }
  } catch (e: any) {
    yield put(actionsAuth.changeState(AuthStates.AUTHENTICATION_FAILED));
    toast({
      type: "error",
      message: e.message || "No se pudo iniciar sessión",
    });
  }
}

function* verificationCodeSaga({ payload }: PayloadAction<string>) {
  try {
    const res: Response<UserData> = yield call(verifyCodeService, payload);
    if (res.success && res.item) {
      setTrackEvent(res);
      yield put(
        actionsAuth.setUser({
          user: res.item,
          state: AuthStates.AUTHENTICATED,
        }),
      );
      if (res?.item?.config?.sbx_crm?.utils?.get_business_days) {
        yield put(actionsConfig.getBusinessDays());
      }
    } else {
      if (res.item?.recover_password_expired) {
        yield put(actionsAuth.changeState(AuthStates.USER_PASSWORD_EXPIRED));
        throw Error("Password expired, please change the password!");
      } else {
        throw Error(res.message);
      }
    }
  } catch (e: any) {
    yield put(actionsAuth.changeState(AuthStates.AUTHENTICATION_FAILED));
    toast({
      type: "error",
      message: "This session couldn't be verified with this code!",
    });
  }
}

function* generateLinkToUserSaga({ payload }: PayloadAction<string>) {
  try {
    const res: Response<string> = yield call(
      generateLinkToUserService,
      payload,
    );
    if (res.success && res?.item) {
      yield put(actionsAuth.setLinkGenerated(res.item));
    } else {
      yield put(actionsAuth.setMessage(res.message));
      throw Error(res.message);
    }
  } catch (e: any) {
    yield put(actionsAuth.changeStateLnk(AuthStates.LINK_TO_LOGIN_FAILED));
    toast({ type: "error", message: e.message ?? "Couldn't generate code!" });
  }
}

function* loginAsSaga({ payload }: PayloadAction<{ username: string }>) {
  try {
    const res: Response<UserData> = yield call(loginAs, payload);
    if (res.success && res.item) {
      session.setLoginAs(res.item.email!);
      TrackEventMonitor.setMetadataExtra({
        login_as: {
          id: res.item.id,
          email: res.item.email,
        },
      });
      session.setLoginAs(res.item.email!);
      session.setToken(res.item.token!);
      setUserSentry(res.item);
      yield put(
        actionsAuth.setUser({
          user: res.item,
          state: AuthStates.AUTHENTICATED,
        }),
      );
      if (res?.item?.config?.sbx_crm?.utils?.get_business_days) {
        yield put(actionsConfig.getBusinessDays());
      }
    } else {
      yield put(actionsAuth.setMessage(res.message));
      throw Error(res.message);
    }
  } catch (e: any) {
    yield put(actionsAuth.changeState(AuthStates.AUTHENTICATION_FAILED));
    toast({
      type: "error",
      message: e.message || "No se pudo iniciar sessión",
    });
  }
}

function* meSaga({ payload = true }: PayloadAction<boolean | undefined>) {
  try {
    const res: Response = yield call(me);
    if (res.success) {
      if (!session.isLoginAs) {
        setTrackEvent(res);
      } else {
        if (session.mainSession) {
          const token = session.mainSession;
          const resMain: Response = yield call(me, {
            authorization: "Bearer " + token,
          });
          if (resMain?.item?.id) {
            TrackEventMonitor.setMetadataExtra({
              user_email: resMain.item.email,
              user_id: resMain.item.id,
            });
          }
        }

        TrackEventMonitor.setMetadataExtra({
          login_as: {
            user: res.item.id,
            user_email: res.item.email,
          },
        });
      }

      const user = Object.assign(
        res.item,
        permissionListToMap(res.item.permissions),
      );

      yield put(actionsAuth.setUser({ user, state: AuthStates.AUTHENTICATED }));

      if (user?.config?.sbx_crm?.utils?.get_business_days) {
        yield put(actionsConfig.getBusinessDays());
      }
    } else {
      if (res.item?.recover_password_expired) {
        yield put(actionsAuth.changeState(AuthStates.USER_PASSWORD_EXPIRED));
        throw Error("Password expired, please change the password!");
      } else {
        throw Error(res.message);
      }
    }
  } catch (e: any) {
    yield put(actionsAuth.changeState(AuthStates.AUTHENTICATION_FAILED));
    logoutService(payload);
    toast({
      type: "error",
      message: e.message || "No se pudo recuperar sessión",
    });
  }
}

function* forgotPasswordSaga({
  payload,
}: ReturnType<typeof actionsAuth.forgotPassword>) {
  try {
    const res: Response = yield call(forgotPasswordService, payload);
    if (res?.success) {
      success("Por favor revisar su correo electronico.");
      yield put(actionsAuth.setForgotPassword());
    } else {
      error(res.error || res.message || "No se pudo restablecer la contraseña");
      yield put(
        actionsAuth.setRejectedState({
          rejectedState: AuthStates.FORGOT_PASSWORD_FAILED,
        }),
      );
    }
  } catch (e: any) {
    yield put(
      actionsAuth.setRejectedState({
        rejectedState: AuthStates.FORGOT_PASSWORD_FAILED,
      }),
    );
    toast({
      type: "error",
      message: e.message || "No se pudo recuperar la sesión",
    });
  }
}

function* recoverPasswordSaga({
  payload,
}: ReturnType<typeof actionsAuth.recoverPasswordWithCode>) {
  try {
    const res: Response = yield call(changePasswordWithCodeService, payload);
    if (res?.success) {
      success("Contraseña restablecida!");
      yield put(
        actionsAuth.login({
          password: payload.password,
          username: payload.username,
        }),
      );
    } else {
      error(res.error || res.message || "No se pudo restablecer la contraseña");
      yield put(
        actionsAuth.setRejectedState({
          rejectedState: AuthStates.RECOVER_FAILED,
        }),
      );
    }
  } catch (e: any) {
    toast({
      type: "error",
      message: e.message || "No se pudo recuperar la sesión",
    });
  }
}

function* generateCodeToActivateMfaByEmailSaga() {
  try {
    const { user } = yield select(authReducer);
    const res: Response = yield call(
      mfaSendCodeService,
      (user as UserData).config?.sbx_crm.extra_setup.mfa_template_key ?? "",
    );
    if (res.success) {
      yield put(actionsAuth.changeStateMfa(AuthStates.CODE_SEND));
      toast({ type: "success", message: "Code send!" });
    } else {
      throw Error;
    }
  } catch (e) {
    toast({ type: "error", message: "Couldn't generate code to activate mfa" });
  }
}

function* verificationCodeToActivateSaga({ payload }: PayloadAction<string>) {
  try {
    const { user } = yield select(authReducer);
    const res: Response<UserData> = yield call(
      verifyCodeToActivateService,
      payload,
    );
    if (res.success) {
      yield put(
        actionsAuth.updateUser({ ...user, mfa_email: res.item?.mfa_email }),
      );
      yield put(actionsAuth.changeStateMfa(AuthStates.MFA_EMAIL_ACTIVATED));
    } else {
      if (res.item?.recover_password_expired) {
        yield put(actionsAuth.changeState(AuthStates.USER_PASSWORD_EXPIRED));
        throw Error("Password expired, please change the password!");
      } else {
        throw Error(res.message);
      }
    }
  } catch (e: any) {
    yield put(actionsAuth.changeStateMfa(AuthStates.MFA_EMAIL_FAILED));
    toast({
      type: "error",
      message: "This session couldn't be verified with this code!",
    });
  }
}

export default function* authSagas(): any {
  return yield all([
    takeEvery(AuthActions.AUTHENTICATE, loginSaga),
    takeEvery(AuthActions.AUTHENTICATE_WITH_LINK, loginWithLinkSaga),
    takeEvery(AuthActions.AUTHENTICATE_AS, loginAsSaga),
    takeEvery(AuthActions.ME, meSaga),
    takeEvery(AuthActions.VERIFICATION_CODE, verificationCodeSaga),
    takeEvery(
      AuthActions.GET_CODE_TO_ACTIVATE,
      generateCodeToActivateMfaByEmailSaga,
    ),
    takeEvery(AuthActions.ACTIVATE_MFA_EMAIL, verificationCodeToActivateSaga),
    takeEvery(AuthActions.FORGOT_PASSWORD, forgotPasswordSaga),
    takeEvery(AuthActions.RECOVER_PASSWORD, recoverPasswordSaga),
    takeEvery(AuthActions.GET_LINK_TO_USER, generateLinkToUserSaga),
  ]);
}
