import ModalComponent from "../ModalComponent/ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { actionsModal, ModalTypes } from "../../../../store/Modal/Slice";
import { DataProvider } from "../../../../types/DataProvider";
import React, { ChangeEvent, FormEvent, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPlusCircle,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FormGroup, Label } from "reactstrap";
import { ProviderType, ProviderType3Options } from "../../../../types/ProviderType";
import { plainToClass } from "class-transformer";
import useIgnoreFirstEffect from "../../../../hooks/useIgnoreFirstEffect";
import { actionsDataProvider } from "../../../../store/DataProvider/Slice";
import { RootState } from "../../../../store/Reducers";
import { DataProviderStates } from "../../../../store/DataProvider/Types";
import useTranslate from "../../../../hooks/useTranslate";
import { Checkbox } from "antd";
import EditorComponent from "../../EditorComponent/EditorComponent";
import SelectComponent from "../../FieldComponents/SelectComponent";
import { capitalize } from "../../../../utils";
import { useRouter } from "next/router";

export interface IPropsCreateProvider {
  type: ModalTypes.CREATE_DATA_PROVIDER;
  cs_options: { label: string, value: string }[]
}

interface IProps {
  open: boolean;
  data: IPropsCreateProvider;
}

const initialProvider = plainToClass(DataProvider, {
  provider_type: ProviderType.SBX,
  name: "",
  value_field: "_KEY",
  label_field: "",
});

const CreateDataProviderModal = ({ open, data }: IProps) => {
  const dispatch = useDispatch();
  const { state, dataProvider } = useSelector(
    (state: RootState) => state.DataProviderReducer
  );
  const [provider, setProvider] = useState(initialProvider);
  const loading = state === DataProviderStates.PROVIDER_PENDING;
  const { t } = useTranslate("common");
  const history = useRouter();
  
  // const [isCloudScript, setIsCloudScript] = useState(false);

  function onClose() {
    dispatch(
      actionsModal.closeModal({ type: ModalTypes.CREATE_DATA_PROVIDER })
    );
  }

  useIgnoreFirstEffect(() => {

    if (state === DataProviderStates.PROVIDER_RESOLVED || state === DataProviderStates.DATA_RESOLVED) {
      dispatch(
        actionsModal.closeModal({ type: ModalTypes.CREATE_DATA_PROVIDER })
      );
    }
  }, [state]);

  useEffect(() => {
    if(dataProvider?.id && provider.name && provider.provider_type) {
      history.push(`${history.pathname}/${provider.provider_type.toLowerCase()}/${dataProvider.id}`);
    }
  }, [dataProvider]);

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (provider.query && Object.keys(provider.query).length === 0) {
      delete provider.query;
    }

    dispatch(actionsDataProvider.addDataProvider(provider));
  }

  function onChange(e: ChangeEvent<HTMLInputElement> | { [key: string]: any }) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let value_field = provider.value_field;
    let provider_type = provider.provider_type;
    if (e.target.name === "provider_type") {
      // value_field = value ? "id" : "_KEY";
      switch(true) {
        case !value:
          value_field = "_KEY";
          provider_type = ProviderType.DATABASE;
          break;
        default:
          value_field = "id";
          provider_type = value;
          break;
      }
      // if(!value) {
      //   provider_
      // }
      // provider_type = !value ? ProviderType.DATABASE : ProviderType.SBX;
    }
    setProvider({
      ...provider,
      [e.target.name]: value,
      value_field,
      provider_type,
    });
  }

  useIgnoreFirstEffect(() => {
    if (provider.provider_type === ProviderType.DATABASE && provider.query) {
      setProvider({ ...provider, query: undefined });
    }
  }, [provider]);

  const providerOptionsType = useMemo(() => {
    return Object.entries(ProviderType3Options).map(([key, value]) => ({
      value: key,
      label: capitalize(value.toLowerCase()),
    }));
  }, []);

  return (
    <ModalComponent
      isLoading={loading}
      size="lg"
      isOpen={open}
      title={
        <>
          <FontAwesomeIcon className="text-primary" icon={faPlusCircle} /> Nuevo
          proveedor de datos
        </>
      }
      footer={
        <>
          <button
            disabled={loading}
            form="newProviderId"
            className="btn btn-primary btn-sm"
          >
            <FontAwesomeIcon
              icon={loading ? faSpinner : faCheck}
              spin={loading}
            />{" "}
            {" " + t("save")}
          </button>
          <button
            disabled={loading}
            onClick={onClose}
            className="btn btn-light btn-sm"
          >
            <FontAwesomeIcon
              icon={loading ? faSpinner : faTimes}
              spin={loading}
            />{" "}
            {" " + t("cancel")}
          </button>
        </>
      }
      toggle={onClose}
    >
      <form onSubmit={onSubmit} id="newProviderId">
        <FormGroup>
          <Label className="me-2">Provider Type: </Label>
          <div style={{ position: 'relative', zIndex: 5}}>
            <SelectComponent
                disabled={loading}
                options={providerOptionsType}
                value={providerOptionsType.find(p => p.value === provider.provider_type)}
                onChange={(e) => {
                  onChange({
                    target: { value: e.value, type: "text", name: "provider_type" },
                  })
                }}
                name="provider_type"
                id="provider_type"
            />
          </div>
          {/* <Switch
            disabled={loading}
            onChange={(e) =>
              onChange({
                target: { checked: e, type: "checkbox", name: "provider_type" },
              })
            }
            checked={provider.provider_type === ProviderType.SBX}
          /> */}
        </FormGroup>
        {
          provider.provider_type === ProviderType.REST && (
            <FormGroup>
              <Checkbox
                className="mr-2"
                disabled={loading}
                // onChange={() => setIsCloudScript(!isCloudScript)}
                checked={true}
              />
              <Label>CloudScript</Label>
            </FormGroup>
          )
        }
        <FormGroup>
          <Label>Nombre:</Label>
          <input
            disabled={loading}
            name="name"
            required
            onChange={onChange}
            type="text"
            value={provider.name}
            className="form-control"
          />
        </FormGroup>
        {provider.provider_type === ProviderType.SBX && (
          <FormGroup>
            <Label>Query</Label>
            <EditorComponent
              mode="json"
              onChange={(e) => {
                onChange({
                  target: {
                    value: e,
                    name: "query",
                    type: "json",
                  },
                });
              }}
              width="100%"
              height="500px"
              name="UNIQUE_ID_OF_DIV"
            />
          </FormGroup>
        )}
        {provider.provider_type === ProviderType.REST && (
          <FormGroup>
            <Label>Select a CloudScript</Label>
            <SelectComponent
              disabled={loading}
              loading={loading}
              options={data.cs_options}
              value={data.cs_options.find(cloud => cloud.value === JSON.parse(provider.query || '{}')?.option?.value)}
              onChange={(e) => {
                const value_string = JSON.stringify({ subtype: 'cloudscript', _KEY: e.value });
                onChange({
                  target: {
                    value: value_string,
                    name: "query",
                    type: "json",
                  },
                });
              }}
              name="cloudscript"
              id="cloudScriptSelect"
            />
          </FormGroup>
        )}
      </form>
    </ModalComponent>
  );
};

//use it
export default CreateDataProviderModal;
