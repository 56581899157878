import IField from "../../../types/FormBuilder/IField";
import React, { useContext, useEffect, useReducer, useState } from "react";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import { getAppointmentByKeysAndKey } from "../../../services/backend/AppointmentService";
import {
  capitalize,
  convertDateToNumberDate,
  convertNumberAndTimeToDate,
  convertNumberDateToDate,
  getDataFromKey,
  getFieldNameFromKeyField,
  getFormat,
  getFormatDate,
  getTimeFormat,
  getValuesByKeys,
  stringUtils,
  toast,
} from "../../../utils";
import PopoverComponent from "../../Shared/PopoverComponent";
import { Appointment } from "../../../types/Appointment";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProcessData } from "../../../types/Task";
import { TaskComponentContext } from "../../../context/TaskContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import CalendarPickerComponent from "../../Shared/FieldComponents/CalendarPicker/CalendarPickerComponent";
import { buttonElementClick } from "../../CalendarComponent/PopEvent";
import { EventDropArg } from "@fullcalendar/react";
import { plainToClass } from "class-transformer";
import TimeLineComponent from "../../LayoutComponenents/TimeLineComponent";
import useTranslate from "../../../hooks/useTranslate";
import { DateClickArg, EventResizeDoneArg } from "@fullcalendar/interaction";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import DateComponent from "../../Shared/FieldComponents/DateComponent";
import useOnInit from "../../../hooks/useOnInit";
import { findAllByModel } from "../../../services/backend/SbxService";

interface IState {
  loading: boolean;
  appointments: Appointment[];
  currentAppointment?: Appointment;
  positionX?: number;
  positionY?: number;
  event?: Appointment[];
  excludeDays: Date[];
  excludeHours: Date[];
  eventValues: { [key: string]: ProcessData };
  editable: boolean;
}

const initialState: IState = {
  loading: false,
  appointments: [],
  excludeHours: [],
  excludeDays: [],
  eventValues: {},
  editable: false,
};

export function validateRangeTime(
  dat1: Date,
  date2: Date,
  date3: Date,
  date4: Date,
) {
  const v1 = dat1.getTime(),
    v2 = date2.getTime(),
    v3 = date3.getTime(),
    v4 = date4.getTime();

  function betweenRange(a: number, b: number, validate: number) {
    return validate >= a && validate <= b;
  }

  return (
    betweenRange(v3, v4, v1) ||
    betweenRange(v3, v4, v2) ||
    betweenRange(v1, v2, v3) ||
    betweenRange(v1, v2, v4)
  );
}

export function validateTimes(
  items: Appointment[],
  { start, end }: { start: Date; end: Date },
) {
  return items.some((app2) =>
    validateRangeTime(
      start,
      end,
      convertNumberAndTimeToDate(app2.start_date, app2.start_time),
      convertNumberAndTimeToDate(app2.due_date, app2.end_time),
    ),
  );
}

const { reducer, actions } = createSlice({
  name: "pop",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setEventValues: (
      state,
      action: PayloadAction<{ [key: string]: ProcessData }>,
    ) => {
      state.eventValues = action.payload;
    },
    setAppointments: (state, action: PayloadAction<Appointment[]>) => {
      state.appointments = action.payload;
      state.loading = false;
    },
    setPositions(
      state,
      action: PayloadAction<{
        positionX: number;
        positionY: number;
        event: Appointment[];
      }>,
    ) {
      state.positionY = action.payload.positionY;
      state.positionX = action.payload.positionX;
      state.event = action.payload.event;
    },
    getCurrentAppointment: (
      state,
      action: PayloadAction<{
        process: { [key: string]: ProcessData };
        field: IField;
      }>,
    ) => {
      const appKey = JSON.parse(
        action.payload.process[action.payload.field?.name]?.value ??
          `{"appointment_key": ""}`,
      ).appointment_key;
      state.currentAppointment = state.appointments.find(
        (app) => app._KEY === appKey,
      );
    },
    setExcludeTimes: (state, action: PayloadAction<Date[]>) => {
      state.excludeHours = action.payload;
    },
    setEditable: (state, action: PayloadAction<boolean>) => {
      state.editable = action.payload;
    },
    updateEvent: (state, action: PayloadAction<Appointment[]>) => {
      state.event = action.payload;
    },
  },
});

export interface CalendarPickerProps {
  onChange?: (start: Date, end?: Date) => void;
  value: Date;
  timeSecondary?: Date;
  id: string;
  required?: boolean;
  appointmentConfigNew?: {
    title: string;
    color: string;
    data: any;
    allDay: boolean;
    editable: boolean;
    selectable: boolean;
  };
}

export interface AppointmentValueProp {
  date: string;
  time: string;
  appointment_key: string;
  type: "crm_user" | "account";
  title: string;
}

const cacheData = new Map<string, Map<string, Record<string, any>>>();
const IntermediateAppointment = ({
  pickerProps,
  fieldProps,
  process = {},
  value,
  preview,
}: {
  pickerProps: CalendarPickerProps;
  fieldProps: IField;
  preview?: boolean;
  value?: AppointmentValueProp;
  process?: Record<string, ProcessData>;
}) => {
  const { watch } = useContext(TaskComponentContext);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslate("common");
  const {
    appointments,
    loading,
    positionX,
    positionY,
    event,
    eventValues,
    currentAppointment,
    editable,
  } = state as IState;

  useEffect(() => {
    if (process && appointments.length && fieldProps) {
      dispatch(actions.getCurrentAppointment({ process, field: fieldProps }));
    }
  }, [process, appointments]);

  function onChange(start: Date, end?: Date) {
    const error = validateTimes(
      appointments.filter((app) => app._KEY !== currentAppointment?._KEY),
      {
        start,
        end: end ?? start,
      },
    );
    if (!error && pickerProps.onChange) {
      if (start.getTime() >= (end ?? start).getTime()) {
        const newEndTime = new Date(start);
        newEndTime.setMinutes(start.getMinutes() + 30);
        pickerProps.onChange(start, newEndTime);
      } else {
        pickerProps.onChange(start, end);
      }
    } else {
      toast({
        type: "warn",
        message: "This event intersects with another event!",
        options: {},
      });
    }
  }

  //get appointments
  useAsyncEffect(async () => {
    try {
      if (
        fieldProps.format_rules_definition?.appointment_props?.type &&
        Object.keys(process).length &&
        !preview &&
        fieldProps.format_rules_definition?.appointment_props?.account_list
          ?.length
      ) {
        dispatch(actions.setLoading(true));
        const { type, account_list, data } =
          fieldProps.format_rules_definition.appointment_props;
        const _data = {
          ...process,
          ...eventValues,
        };

        //get values from process data
        const keys = getValuesByKeys(account_list, _data);

        //validate extra keys without table inputs
        let extraKeys: string[] = getValuesByKeys(
          Object.entries(data ?? {})
            .filter(([, value]) => !value.key)
            .map(([, value]) => value.value),
          _data,
        ) as string[];

        const extraKeysTable = Object.entries(data ?? {})
          .filter(([, value]) => value.key)
          .reduce((acc: string[], [, value]) => {
            const val = getValuesByKeys(value.value, _data) as any;
            if (Array.isArray(val)) {
              //all values of table

              if (!value.only_selected) {
                val.map((v) => {
                  acc.push(getDataFromKey(v, value.key));
                });
              } else {
                val.map((v) => {
                  const isSelected = getDataFromKey(v, value.only_selected);
                  if (isSelected) {
                    acc.push(getDataFromKey(v, value.key));
                  }
                });
              }
            }
            return acc;
          }, []);

        //find all appointment by keys or values in data
        if (extraKeys && extraKeys?.length > 0) {
          // there are some values like ["1,2,3"] with many items in one position and should be separated
          extraKeys = extraKeys.reduce((acc: string[], item: string) => {
            const keys = item.split(",");
            keys.forEach((key) => {
              if (key) {
                acc.push(key);
              }
            });

            return acc;
          }, []);
        }

        const res = await getAppointmentByKeysAndKey(
          type,
          keys as string[],
          [...extraKeys, ...extraKeysTable] as string[],
          value?.appointment_key,
        );

        if (res.success) {
          dispatch(actions.setAppointments(res.items));
          const extraData = await Promise.all(
            res.items.reduce((acc: Promise<any>[], app: Appointment) => {
              if (app.data) {
                let data = stringUtils.toObject<Record<string, string[]>>(
                  app.data,
                );

                Object.entries(data ?? {}).forEach(([key, value]) => {
                  if (!cacheData.has(key)) cacheData.set(key, new Map());

                  const cache = cacheData.get(key);

                  const newValueKeys = value.filter((e) => e && !cache?.has(e));
                  if (newValueKeys.length) {
                    acc.push(
                      findAllByModel({
                        row_model: key,
                        where: { keys: newValueKeys },
                        noGetOrganization: true,
                      }),
                    );
                  }
                });
              }
              return acc;
            }, []),
          );

          extraData.forEach((data) => {
            if (data.success) {
              data.items.forEach((item: any) => {
                if (cacheData.has(data.row_model)) {
                  const cache = cacheData.get(data.row_model);
                  cache?.set(item._KEY, item);
                }
              });
            }
          });
        } else {
          dispatch(actions.setLoading(false));
        }
      }
    } catch (e) {
      console.log("error capturing data from values  => {}", e);
      dispatch(actions.setLoading(false));
    }
  }, [
    dispatch,
    fieldProps?.format_rules_definition?.appointment_props,
    process,
    eventValues,
    value?.appointment_key,
  ]);

  function eventDrop(e: EventDropArg) {
    const data = plainToClass(Appointment, e.event._def.extendedProps.data);
    if (data._KEY === currentAppointment?._KEY) {
      console.log("dragging");
      const startDate = new Date(pickerProps.value);
      if (pickerProps.onChange && startDate) {
        startDate.setDate(startDate.getDate() + e.delta.days);
        startDate.setMonth(startDate.getMonth() + e.delta.months);
        startDate.setFullYear(startDate.getFullYear() + e.delta.years);
        startDate.setMilliseconds(
          startDate.getMilliseconds() + e.delta.milliseconds,
        );
        onChange(
          startDate,
          convertNumberAndTimeToDate(
            parseInt(convertDateToNumberDate(startDate)),
            getTimeFormat(pickerProps.timeSecondary ?? startDate).stringTime,
          ),
        );
      }
    }
  }

  function eventResize(e: EventResizeDoneArg) {
    const data = plainToClass(Appointment, e.event._def.extendedProps.data);
    if (data._KEY === currentAppointment?._KEY) {
      console.log("resizing");
      const start = pickerProps.value;
      if (start) {
        if (pickerProps.timeSecondary) {
          const newEndTime = new Date(pickerProps.timeSecondary);
          newEndTime.setDate(newEndTime.getDate() + e.endDelta.days);
          newEndTime.setMonth(newEndTime.getMonth() + e.endDelta.months);
          newEndTime.setFullYear(newEndTime.getFullYear() + e.endDelta.years);
          newEndTime.setMilliseconds(
            newEndTime.getMilliseconds() + e.endDelta.milliseconds,
          );
          onChange(start, newEndTime);
        }
      }
    }
  }

  const [getTitleByProps, setTitleByProps] = useState("");

  //Detecting changes in real time
  useAsyncEffect(async () => {
    if (watch && fieldProps.format_rules_definition.appointment_props) {
      const subscription = watch((value, { name }) => {
        const { account_list, data = {} } =
          fieldProps.format_rules_definition.appointment_props ?? {};
        const replacement = account_list?.reduce((values: any, str) => {
          const _name = getFieldNameFromKeyField(str.toString());
          if (name === _name) {
            values[_name] = { value: value[_name] };
          }
          return values;
        }, {});

        Object.entries(data ?? {})
          .map(([, value]) => getFieldNameFromKeyField(value.value))
          .forEach((key) => {
            if (name === key) {
              replacement[key] = { value: value[key] };
            }
          });

        if (Object.values(replacement).length) {
          dispatch(
            actions.setEventValues({ ...state.eventValues, ...replacement }),
          );
        }
        let _title =
          fieldProps.format_rules_definition.appointment_props?.title ?? "";
        if (_title.includes("$")) {
          const names = _title.split("$").reduce((array: string[], name) => {
            const nn = name.replace("{", "").split("}").shift();
            if (nn) {
              array.push(getFieldNameFromKeyField("${" + `${nn}}`));
            }
            return array;
          }, []);
          names.forEach((name) => {
            _title = _title.replaceAll(
              "${" + `${name}}`,
              value[name] || process[name]?.value || "",
            );
          });
          setTitleByProps(_title);
        } else {
          setTitleByProps(_title);
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [watch, process, state.eventValues]);

  //click in event date: capture positions and find hours available,
  function handleEvent(
    value: Date | null,
    appo: Appointment[],
    event: MouseEvent,
  ) {
    dispatch(
      actions.setPositions({
        positionY: event.pageY,
        positionX: event.pageX,
        event: appo,
      }),
    );
    dispatch(actions.setEditable(false));
    buttonElementClick(fieldProps?.id + "POP");
  }

  function clickNewEvent(e: DateClickArg) {
    if (pickerProps.value || currentAppointment) return;
    const data = plainToClass(Appointment, {
      start_date: convertDateToNumberDate(e.date),
      due_date: convertDateToNumberDate(e.date),
      start_time: getTimeFormat(e.date).stringTime,
      end_time: getTimeFormat(e.date).stringTime,
    });

    dispatch(
      actions.setPositions({
        positionY: e.jsEvent.pageY,
        positionX: e.jsEvent.pageX,
        event: [data],
      }),
    );
    dispatch(actions.setEditable(true));
    buttonElementClick(fieldProps?.id + "POP");
  }

  const positions = {
    top: positionY ? `${Math.abs(positionY)}px` : undefined,
    left: positionX ? `${Math.abs(positionX)}px` : undefined,
  };

  const NewEventComponent = (props: {
    event: Appointment;
    appointments: Appointment[];
    toggle: (val: boolean) => void;
  }) => {
    const [app, onChangeV] = useState(props.event);
    const [items, setItems] = useState<Appointment[]>([]);
    const [error, setError] = useState(false);

    useOnInit(
      () => {
        setItems(
          props.appointments.filter(
            (app2) =>
              app2._KEY !== currentAppointment?._KEY &&
              app2.start_date.toString() === app.start_date.toString(),
          ),
        );
      },
      [props.appointments, currentAppointment, app],
      [app !== null],
    );

    useEffect(() => {
      if (items.length) {
        setError(
          validateTimes(items, {
            start: convertNumberAndTimeToDate(app.start_date, app.start_time),
            end: convertNumberAndTimeToDate(app.due_date, app.end_time),
          }),
        );
      }
    }, [items, app]);

    function onChangeValue(key: string, value: string | number) {
      onChangeV({ ...app, [key]: value });
    }

    function updateEvent() {
      if (pickerProps.onChange && event?.length && !error) {
        const s = convertNumberAndTimeToDate(app.start_date, app.start_time);
        const d = convertNumberAndTimeToDate(app.due_date, app.end_time);
        onChange(s, d);
        dispatch(
          actions.updateEvent([
            {
              ...event[0],
              start_date: parseInt(convertDateToNumberDate(s)),
              due_date: parseInt(convertDateToNumberDate(d)),
              start_time: getTimeFormat(s).stringTime,
              end_time: getTimeFormat(d).stringTime,
            },
          ]),
        );
        props.toggle(false);
      }
    }

    return (
      <Row style={{ maxWidth: "500px", maxHeight: "400px" }}>
        <Col md={6}>
          <b>Basics data</b>
          <FormGroup>
            <Label>{t("common:startDate")}</Label>
            <DateComponent
              isOutsideRange={(day) => {
                return (
                  fieldProps.format_rules_definition.appointment_props
                    ?.disabled_days ?? []
                ).some((num) => num === day?.day());
              }}
              value={convertNumberDateToDate(app.start_date)}
              onChange={(e) =>
                e
                  ? onChangeValue("start_date", convertDateToNumberDate(e))
                  : null
              }
              id={"start_date_pop"}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("common:endDate")}</Label>
            <DateComponent
              isOutsideRange={(day) => {
                return (
                  fieldProps.format_rules_definition.appointment_props
                    ?.disabled_days ?? []
                ).some((num) => num === day?.day());
              }}
              value={convertNumberDateToDate(app.due_date)}
              onChange={(e) =>
                e ? onChangeValue("due_date", convertDateToNumberDate(e)) : null
              }
              id={"due_date_pop"}
            />
          </FormGroup>
          {error && (
            <FormGroup>
              <small className={"text-danger"}>This range is not valid!</small>
            </FormGroup>
          )}
          <FormGroup>
            <Label>{t("common:startTime")}</Label>
            <Input
              type="time"
              className={error ? "text-danger" : ""}
              value={app.start_time}
              onChange={(e) => onChangeValue("start_time", e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("common:endTime")}</Label>
            <Input
              type="time"
              className={error ? "text-danger" : ""}
              value={app.end_time}
              onChange={(e) => onChangeValue("end_time", e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={6} className="h-50 overflow-auto">
          <b>Other events</b>
          {!items.length && <div>{t("no_results")}</div>}
          {items.map((app2) => {
            const existConflict = validateRangeTime(
              convertNumberAndTimeToDate(app.start_date, app.start_time),
              convertNumberAndTimeToDate(app.due_date, app.end_time),
              convertNumberAndTimeToDate(app2.start_date, app2.start_time),
              convertNumberAndTimeToDate(app2.due_date, app2.start_time),
            );

            return (
              <div
                key={app2._KEY}
                className={
                  "bg-light shadow-sm rounded border  text-gray my-1 d-flex align-items-center mx-3 " +
                  (existConflict ? "border-danger" : "border-light")
                }
              >
                <div
                  style={{ width: "30px" }}
                  className="d-flex justify-content-center"
                >
                  <div
                    style={{ width: "6px", height: "6px" }}
                    className="bg-primary rounded-circle me-2"
                  />
                </div>
                <div>
                  <small>
                    {app2.summary} <br />
                    {getFormatDate(
                      convertNumberAndTimeToDate(
                        app2.start_date,
                        app2.start_time,
                      ),
                      "MMMM d, yyyy h:mm aa",
                    )}
                    <br />
                    {getFormatDate(
                      convertNumberAndTimeToDate(app2.due_date, app2.end_time),
                      "MMMM d, yyyy h:mm aa",
                    )}
                  </small>
                </div>
              </div>
            );
          })}
        </Col>
        <Col>
          <FormGroup className="d-flex justify-content-end">
            {pickerProps.value && (
              <Button
                onClick={() => dispatch(actions.setEditable(false))}
                type={"button"}
                size={"sm"}
                color={"light"}
              >
                {t("common:back")}
              </Button>
            )}
            <Button
              type={"button"}
              disabled={error}
              className="ms-2"
              onClick={updateEvent}
              size={"sm"}
              color={"primary"}
            >
              {t(currentAppointment ? "update" : "create")}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    );
  };

  //get all events from local cache
  const getExtraEvents = (appointment: Appointment) => {
    return Object.entries(
      stringUtils.toObject<Record<string, string[]>>(appointment.data ?? "") ??
        {},
    ).map(([k, value]) => {
      const description = value
        .map((v) => {
          if (cacheData.has(k)) {
            const cache = cacheData.get(k);
            if (cache?.has(v)) {
              return cache.get(v)?.name;
            }
          }
          return "";
        })
        .join(", ");

      return {
        title: capitalize(k),
        body: (
          <div className="text-gray">
            <small>{description}</small>
          </div>
        ),
      };
    });
  };

  return (
    <div className="position-relative">
      {loading && (
        <div
          style={{ top: "10px", right: "10px", zIndex: 1000 }}
          className="position-absolute"
        >
          <FontAwesomeIcon spin icon={faSpinner} />
        </div>
      )}
      <CalendarPickerComponent
        {...pickerProps}
        appointmentConfigNew={{
          title: getTitleByProps,
          color: "red",
          data: {} as Appointment,
          allDay: false,
          editable: true,
          selectable: true,
        }}
        hiddenDays={
          fieldProps.format_rules_definition.appointment_props?.disabled_days
        }
        disabled={loading}
        currentAppointment={currentAppointment}
        events={appointments}
        dateClickEvent={clickNewEvent}
        eventDrop={eventDrop}
        eventUpdate={handleEvent}
        eventResize={eventResize}
      />
      <div style={{ ...positions, position: "fixed" }}>
        <PopoverComponent
          zIndex={10000}
          label={""}
          trigger="click"
          placement="bottom"
          id={fieldProps?.id + "POP"}
        >
          {(open, toggle) => {
            return (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div>{getTitleByProps}</div>
                  <Button
                    onClick={() => toggle(false)}
                    type={"button"}
                    color={"link"}
                    className="text-gray"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </div>
                {!editable ? (
                  <div style={{ width: "300px" }}>
                    <TimeLineComponent
                      noShadow
                      items={
                        event
                          ? Object.keys(event[0])
                              .reduce((ob: any[], key) => {
                                const ev = event[0] as any;
                                const { value, title } = getFormat(
                                  key,
                                  ev,
                                  (value) => value,
                                );
                                if (value) {
                                  const n = {
                                    title: t(title ?? ""),
                                    body: (
                                      <div className="text-gray">
                                        <small>{value}</small>
                                      </div>
                                    ),
                                  };
                                  ob.push(n);
                                }
                                return ob;
                              }, [])
                              .sort((a, b) => a.title.localeCompare(b.title))
                              .concat(...getExtraEvents(event[0]))
                          : []
                      }
                    />

                    {event?.length &&
                      currentAppointment?._KEY === event[0]?._KEY && (
                        <div className={"d-flex justify-content-end"}>
                          <Button
                            type={"button"}
                            color="primary"
                            size={"sm"}
                            onClick={() => dispatch(actions.setEditable(true))}
                          >
                            <FontAwesomeIcon icon={faEdit} /> {t("common:edit")}
                          </Button>
                        </div>
                      )}
                  </div>
                ) : (
                  <>
                    {!!event?.length && editable && (
                      <NewEventComponent
                        event={event[0]}
                        toggle={toggle}
                        appointments={appointments}
                      />
                    )}
                  </>
                )}
              </>
            );
          }}
        </PopoverComponent>
      </div>
    </div>
  );
};

export default IntermediateAppointment;
