import React, { useContext } from "react";
import QueryFetchComponent from "./QueryFetchComponent";
import { State } from "../../../types/State";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import LabelComponent from "../FieldComponents/LabelComponent";
import SelectComponent from "../FieldComponents/SelectComponent";
import { actions, StateLocal } from "./Reducer";
import { getFetch, getOptionsByField } from "./Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faSyncAlt,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SpinnerComponent from "../SpinnerComponent";
import { Switch } from "antd";
import {
  Andor,
  Model,
  SbxConditionType,
  SbxModelField,
} from "../../../types/Sbx";
import { plainToClass } from "class-transformer";
import FieldInput from "./FieldInput";
import { QueryContext } from "./QueryComponent";
import CreatableSelectComponent from "../FieldComponents/CreatableSelectComponent";
import DropdownItemsOptions from "../DropdownItemsOptions/DropdownItemsOptions";

const QueryBuilderComponent = () => {
  const {
    defaultFetchedModels,
    reverseFetchedModels,
    updateFetchQuery,
    deepFetchedModels,
    showFetch,
    fetchedModels,
    state,
    dispatch,
    setDefaultFetchedModels,
    getReverseFetch,
    disableModel,
    includeColumns,
    dataType,
    getModels,
    t,
  } = useContext(QueryContext);

  const { conditions, model, modelFetched } = state;

  return (
    <div>
      <DropdownItemsOptions
        items={[
          {
            label: t("reload"),
            icon: faSyncAlt,
            onClick: () => {
              getModels();
            },
          },
        ]}
      />
      <div className="d-flex flex-column gap-3">
        {showFetch && (
          <QueryFetchComponent
            reverseFetchModels={reverseFetchedModels}
            updateFetchModel={updateFetchQuery}
            defaultFetch={defaultFetchedModels ?? []}
            fetchModels={fetchedModels}
            isLoading={
              state.state === "FETCH_REFERENCES_PENDING" ||
              state.state === State.PENDING ||
              state.build === State.BUILDING
            }
            deepModelsFetch={deepFetchedModels}
            model={model?.value?.name ?? ""}
          />
        )}
        <Card>
          <CardBody>
            <Row>
              {/*Model selection*/}
              <Col sm={12} lg={4}>
                {!disableModel && (
                  <LabelComponent>{t("select_model")}</LabelComponent>
                )}
                <SelectComponent
                  id="models"
                  name="models"
                  disabled={disableModel}
                  value={model}
                  onChange={(opt) => {
                    dispatch(actions.changeModel(opt));
                    setDefaultFetchedModels(
                      getFetch(opt, state.rows, includeColumns).filter(
                        (model) => !model.includes("."),
                      ),
                    );
                    getReverseFetch(state.rows, opt.value.name);
                  }}
                  options={state.rows.map((m) => ({ label: m.name, value: m }))}
                />
              </Col>

              <Col sm={12} lg={8} className="text-end pt-2">
                <LabelComponent>&nbsp;</LabelComponent>
                <br />
                {/*Add conditions*/}
                <Button
                  onClick={() => dispatch(actions.addGroup())}
                  disabled={!model}
                  color="primary"
                  size="sm"
                >
                  <FontAwesomeIcon icon={faPlusCircle} /> {t("add_group")}
                </Button>
                {/*clear conditions*/}
                {!!conditions.length && (
                  <Button
                    onClick={() => dispatch(actions.removeAllGroups())}
                    color="light"
                    size="sm"
                    className="ms-2"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} /> {t("clear")}
                  </Button>
                )}
              </Col>
            </Row>
            {/*Conditions*/}
            <div>
              {state.build === State.BUILDING ||
              (dataType && state.state === StateLocal.SEARCHING) ? (
                <div className="d-flex justify-content-center">
                  <SpinnerComponent />
                </div>
              ) : (
                <>
                  {!conditions.length && (
                    <div className="text-center text-gray mx-2 px-2 py-4">
                      ¡{t("no_groups")}!
                    </div>
                  )}

                  {conditions.map((group, indexG) => {
                    const isFirstItem = indexG === 0;
                    return (
                      <div
                        key={indexG}
                        className="border bg-white shadow-sm border-light my-2 py-3"
                      >
                        <div
                          className={`d-flex justify-content-${
                            isFirstItem ? "end" : "between"
                          } p-2`}
                        >
                          {!isFirstItem && (
                            <Switch
                              checked={group.ANDOR === Andor.AND}
                              unCheckedChildren={"OR"}
                              checkedChildren={t("And")}
                              onChange={(checked) =>
                                dispatch(
                                  actions.changeAndorGroup({
                                    andor: checked ? Andor.AND : Andor.OR,
                                    index: indexG,
                                  }),
                                )
                              }
                            />
                          )}
                          <div>
                            <Button
                              onClick={() =>
                                dispatch(actions.addCondition(indexG))
                              }
                              disabled={!model}
                              color="primary"
                              size="sm"
                            >
                              <FontAwesomeIcon icon={faPlusCircle} />{" "}
                              {t("add_condition")}
                            </Button>
                            {/*clear conditions*/}
                            {!!conditions.length && (
                              <Button
                                onClick={() =>
                                  dispatch(actions.clearConditions(indexG))
                                }
                                color="light"
                                size="sm"
                                className="ms-2"
                              >
                                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                                {t("delete")}
                              </Button>
                            )}
                          </div>
                        </div>

                        {!group.GROUP.length && (
                          <div className="text-center text-gray mx-2 px-2 py-4">
                            ¡{t("no_conditions")}!
                          </div>
                        )}

                        {group.GROUP.map((con, indexC) => {
                          const fieldKey = "field_" + indexC;
                          const isDisable =
                            con.OP === null ||
                            con.OP?.value.condition ===
                              SbxConditionType.EXIST ||
                            con.OP?.value.condition ===
                              SbxConditionType.NO_EXIST;

                          const isReference =
                            con.FIELD?.value.type === SbxModelField.REFERENCE &&
                            con.FIELD?.value.reference_type;
                          const fieldOptions =
                            model?.value.properties.map((m) => ({
                              label: m.name,
                              value: m,
                            })) ?? [];

                          let subFieldOptions =
                            modelFetched[
                              `${con.FIELD?.value.reference_type}`
                            ]?.properties.map((m) => ({
                              label: m.name,
                              value: m,
                            })) ?? [];

                          subFieldOptions = [
                            {
                              label: "_KEY",
                              value: plainToClass(Model, { name: "_KEY" }),
                            },
                            ...subFieldOptions,
                          ];

                          const loadingReferences =
                            state.state === StateLocal.FETCH_REFERENCES_PENDING;

                          const conDisabled =
                            con.FIELD?.value.type === SbxModelField.REFERENCE
                              ? !con.FIELD || !con.SUB_FIELD
                              : !con.FIELD;
                          return (
                            <div
                              key={con.key}
                              className={`${
                                !!indexC ? "px-2" : ""
                              }  py-2 pb-1 justify-content-center d-flex gap-3`}
                            >
                              <Col sm={12} lg={1}>
                                {!!indexC && (
                                  <div className="text-center mt-2">
                                    <LabelComponent>&nbsp;</LabelComponent>
                                    <br />
                                    <Switch
                                      unCheckedChildren={"Or"}
                                      checkedChildren={t("And")}
                                      onChange={(checked) =>
                                        dispatch(
                                          actions.changeCondition({
                                            conditionIndex: indexC,
                                            groupIndex: indexG,
                                            values: {
                                              ANDOR: checked
                                                ? Andor.AND
                                                : Andor.OR,
                                            },
                                          }),
                                        )
                                      }
                                      checked={
                                        con.ANDOR.toUpperCase() === Andor.AND
                                      }
                                    />
                                  </div>
                                )}
                              </Col>
                              <Col sm={12} lg={isReference ? 2 : 4}>
                                <LabelComponent>{t("field")}</LabelComponent>
                                <SelectComponent
                                  loading={loadingReferences}
                                  id={fieldKey}
                                  name={fieldKey}
                                  value={con.FIELD}
                                  onChange={(FIELD: {
                                    label: string;
                                    value: Model;
                                  }) => {
                                    let SUB_FIELD = null;
                                    if (
                                      FIELD.value.type ===
                                        SbxModelField.REFERENCE &&
                                      FIELD.value.reference_type
                                    ) {
                                      SUB_FIELD =
                                        subFieldOptions.find(
                                          (o) => o.value.name === "_KEY",
                                        ) ?? null;
                                    }
                                    dispatch(
                                      actions.changeCondition({
                                        values: {
                                          FIELD,
                                          OP: null,
                                          VAL: null,
                                          SUB_FIELD,
                                        },
                                        groupIndex: indexG,
                                        conditionIndex: indexC,
                                      }),
                                    );
                                  }}
                                  options={fieldOptions}
                                />
                              </Col>
                              {isReference && (
                                <Col sm={12} lg={2}>
                                  <LabelComponent>{t("field")}</LabelComponent>
                                  <CreatableSelectComponent
                                    disabled={loadingReferences}
                                    loading={loadingReferences}
                                    id={fieldKey}
                                    name={fieldKey}
                                    onChange={(SUB_FIELD) =>
                                      dispatch(
                                        actions.changeCondition({
                                          values: {
                                            SUB_FIELD,
                                            OP: null,
                                            VAL: null,
                                          },
                                          conditionIndex: indexC,
                                          groupIndex: indexG,
                                        }),
                                      )
                                    }
                                    value={con.SUB_FIELD}
                                    options={subFieldOptions}
                                  />
                                </Col>
                              )}
                              <Col sm={12} lg={2}>
                                <LabelComponent>
                                  {t("condition")}
                                </LabelComponent>
                                <SelectComponent
                                  disabled={conDisabled}
                                  id={fieldKey}
                                  name={fieldKey}
                                  value={con.OP}
                                  onChange={(OP) =>
                                    dispatch(
                                      actions.changeCondition({
                                        conditionIndex: indexC,
                                        groupIndex: indexG,
                                        values: { OP },
                                      }),
                                    )
                                  }
                                  options={getOptionsByField(
                                    t,
                                    con.SUB_FIELD?.value.type ??
                                      con.FIELD?.value.type,
                                  )}
                                />
                              </Col>
                              <Col sm={12} lg={3}>
                                <LabelComponent>{t("value")}</LabelComponent>
                                <FieldInput
                                  disabled={isDisable}
                                  id={
                                    indexG.toString() + "_" + indexC.toString()
                                  }
                                  value={con.VAL}
                                  onChange={(VAL) =>
                                    dispatch(
                                      actions.changeCondition({
                                        values: { VAL },
                                        groupIndex: indexG,
                                        conditionIndex: indexC,
                                      }),
                                    )
                                  }
                                  condition={con}
                                  type={
                                    (con.OP
                                      ? con.OP.value.condition ===
                                        SbxConditionType.IN
                                        ? "ARRAY_STRING"
                                        : undefined
                                      : undefined) ??
                                    con.SUB_FIELD?.value.type ??
                                    con.FIELD?.value.type
                                  }
                                />
                              </Col>
                              <Col sm={12} lg={1} className="text-center pt-2">
                                <LabelComponent>&nbsp;</LabelComponent>
                                <br />
                                <Button
                                  size="sm"
                                  color="danger"
                                  onClick={() =>
                                    dispatch(
                                      actions.removeCondition({
                                        conditionIndex: indexC,
                                        groupIndex: indexG,
                                      }),
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </Col>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default QueryBuilderComponent;
