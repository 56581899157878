export enum ProviderType {
  DATABASE= "DATABASE",
  SBX = "SBX",
  SBX_SEQUENTIAL_QUERY = "SBX_SEQUENTIAL_QUERY",
  REST = "REST",
  URL = "URL",
  GROUP = "GROUP"
}

export enum ProviderType3Options {
  DATABASE= "DATABASE",
  SBX = "SBX",
  REST = "REST"
}
