import React, { FormEvent, useContext, useEffect, useState } from "react";
import PopoverComponent, {
  responsiveContent,
} from "../Shared/PopoverComponent";
import {
  capitalize,
  convertDateToDDMMMYYYY,
  convertDateToNumberDate,
  convertNumberDateToDate,
  getFormat,
} from "../../utils";
import { Col, Row } from "reactstrap";
import SmallTextComponent from "../Shared/FieldComponents/SmallTextComponent";
import DateComponent from "../Shared/FieldComponents/DateComponent";
import SelectComponent from "../Shared/FieldComponents/SelectComponent";
import TimeComponent from "../Shared/FieldComponents/TimeComponent";
import LargeTextComponent from "../Shared/FieldComponents/LargeTextComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEdit,
  faSpinner,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { State } from "../../types/State";
import { CalendarContext } from "./CalendarComponent";
import { EventCalendar } from "../../types/Calendar";
import AccountSelectComponent from "../Shared/AccountSelectComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Reducers";
import TimeLineComponent from "../LayoutComponenents/TimeLineComponent";
import Permission from "../AuthorityPermission/Permission";
import { Permissions } from "../../types/Permissions";
import InviteSelectorComponent from "../Shared/InviteSelectorComponent";

export const buttonElementClick = (id: string) =>
  document.getElementById(id)?.click();

interface IProps {
  id: string;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  numberDay: string;
  onDelete: (event: EventCalendar) => void;
  t: (field: string) => string;
  disabledAccount?: boolean;
}

const PopEvent = ({
  t,
  id,
  onSubmit,
  numberDay,
  onDelete,
  disabledAccount,
}: IProps) => {
  const { state, onChangeEvent } = useContext(CalendarContext);
  const { users } = useSelector((state: RootState) => state.PermissionReducer);
  const options = state.process.map((process) => ({
    label: capitalize(process.name),
    value: process.id,
  }));

  const userOptions = users.map((user) => ({
    label: capitalize(user.full_name),
    value: user.email,
  }));

  const salesOptions = state.sales.map((sale) => ({
    label: sale.code + " - " + capitalize(sale.name),
    value: sale._KEY,
  }));

  const [confirmDelete, setConfirm] = useState(false);
  const [edit, setEdit] = useState(false);
  const loading = state.state === State.PENDING;
  const { event } = state;

  //const withInitialAccount = useRef(false)

  useEffect(() => {
    if (confirmDelete) setTimeout(() => setConfirm(false), 5000);
  }, [confirmDelete]);

  useEffect(() => {
    if (event?.start) {
      if (!event.data._KEY) {
        setEdit(true);
      } else {
        setEdit(false);
      }
    }
  }, [event?.start]);

  const getValueAddressee = (key: string) =>
    salesOptions.find((opt) => opt.value === key) ?? null;
  const getValueProcess = (id: number) =>
    options.find((opt) => opt.value === id) ?? null;

  return (
    <>
      <div>{numberDay}</div>
      <PopoverComponent
        position={"absolute"}
        label={""}
        trigger="click"
        title={
          t(event?.data._KEY ? "updateEvent" : "newEvent") +
          " " +
          (event ? convertDateToDDMMMYYYY(event?.start) : "")
        }
        placement="bottom"
        id={id}
      >
        <div
          style={{
            width: `${responsiveContent("col-lg-4 col-md-6 col-12")}px`,
          }}
        >
          {event && !edit && (
            <>
              <TimeLineComponent
                noShadow
                title={capitalize(event.title)}
                items={Object.keys(event.data)
                  .reduce((ob: any[], key) => {
                    const ev = event.data as any;
                    const { value, title } = getFormat(
                      key,
                      ev,
                      getValueAddressee,
                    );
                    if (value) {
                      const n = {
                        title: t(`common:${title}`),
                        body: (
                          <div className="text-gray">
                            <small>{value}</small>
                          </div>
                        ),
                      };
                      ob.push(n);
                    }
                    return ob;
                  }, [])
                  .sort((a, b) => a.title.localeCompare(b.title))}
              />
              <div className="d-flex justify-content-end mt-2">
                <button
                  onClick={() => setEdit(true)}
                  className="btn btn-sm btn-primary"
                >
                  <FontAwesomeIcon className="me-1" icon={faEdit} />
                  {t("common:edit")}
                </button>
              </div>
            </>
          )}
          {event && edit && (
            <div className="mb-2">
              <form onSubmit={onSubmit}>
                <Row className="mb-2">
                  <Col lg={12}>
                    <div className="form-group mb-3">
                      <label htmlFor="">{t("common:summary")}</label>
                      <SmallTextComponent
                        disabled={loading}
                        id={"summary"}
                        name={"summary"}
                        required
                        onChange={(e) => onChangeEvent("summary", e)}
                        value={event.data.summary ?? ""}
                      />
                    </div>
                  </Col>

                  <Col lg={6} sm={12}>
                    <div className="form-group mb-3">
                      <label htmlFor="">{t("common:endDate")}</label>
                      <DateComponent
                        disabled={loading}
                        id={event.data.summary ?? ""}
                        onChange={(e) =>
                          onChangeEvent(
                            "due_date",
                            e ? convertDateToNumberDate(e) : e,
                          )
                        }
                        value={
                          event.data.due_date
                            ? convertNumberDateToDate(event.data.due_date)
                            : null
                        }
                      />
                    </div>
                  </Col>

                  <Col lg={6} sm={12}>
                    <div className="form-group mb-3">
                      <label htmlFor="">{t("common:process")}</label>
                      <SelectComponent
                        disabled={loading}
                        name={"workflow_id"}
                        options={options}
                        // required
                        id={"workflow_id"}
                        onChange={(e) => onChangeEvent("workflow_id", e.value)}
                        value={getValueProcess(event.data.workflow_id)}
                      />
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="form-group mb-3">
                      <label htmlFor="">{t("common:client")}</label>
                      <AccountSelectComponent
                        disabled={loading || disabledAccount}
                        // required
                        id="client"
                        name="client"
                        value={event.data.account_data}
                        onChange={(e) => onChangeEvent("account_data", e)}
                      />
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="form-group mb-3">
                      <label htmlFor="">{`${t("common:addressee")} (${t(
                        "common:optional",
                      )})`}</label>
                      <SelectComponent
                        disabled={loading}
                        name={"sales_addressee"}
                        options={salesOptions}
                        id={"sales_addressee"}
                        onChange={(e) =>
                          onChangeEvent("sales_addressee", e.value)
                        }
                        value={getValueAddressee(event.data.sales_addressee)}
                      />
                    </div>
                  </Col>

                  {getValueAddressee(event.data.sales_addressee) && (
                    <Col lg={12}>
                      <div className="form-group mb-3">
                        <label htmlFor="">{t("common:invitees")}</label>
                        <InviteSelectorComponent
                          disabled={loading}
                          id="invitees"
                          name="invitees"
                          value={userOptions.filter(
                            (opt) =>
                              event?.data.invitees
                                ?.split(",")
                                .some((email) => email === opt.value),
                          )}
                          onChange={(e) =>
                            onChangeEvent(
                              "invitees",
                              e ? e.map((e: any) => e.value).join(",") : "",
                            )
                          }
                        />
                      </div>
                    </Col>
                  )}

                  <Col lg={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">{t("common:startTime")}</label>
                      <TimeComponent
                        id={"start_time"}
                        disabled={loading}
                        name={"start_time"}
                        value={event.data.start_time ?? ""}
                        onChange={(e) => onChangeEvent("start_time", e)}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">{t("common:endTime")}</label>
                      <TimeComponent
                        disabled={loading}
                        id={"end_time"}
                        name={"end_time"}
                        value={event.data.end_time ?? ""}
                        onChange={(e) => onChangeEvent("end_time", e)}
                      />
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="form-group mb-3">
                      <label htmlFor="">{t("common:description")}</label>
                      <LargeTextComponent
                        disabled={loading}
                        id={"description"}
                        name={"description"}
                        onChange={(e) => onChangeEvent("description", e)}
                        value={event.data.description ?? ""}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="form-group mb-3 d-flex justify-content-end">
                  <Permission
                    permission={[
                      Permissions.ACCOUNT_EDIT,
                      Permissions.CALENDAR_CREATE,
                    ]}
                  >
                    <button
                      disabled={loading}
                      className="btn btn-primary btn-sm"
                    >
                      <FontAwesomeIcon
                        spin={loading}
                        icon={loading ? faSpinner : faCheckCircle}
                        className="me-1"
                      />
                      <small>{t("common:save")}</small>
                    </button>
                  </Permission>

                  <button
                    onClick={() =>
                      buttonElementClick(convertDateToNumberDate(event.start))
                    }
                    disabled={loading}
                    type="button"
                    className="btn btn-light btn-sm ms-1"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="me-1" />
                    <small>{t("common:cancel")}</small>
                  </button>

                  {event.data._KEY && (
                    <Permission permission={Permissions.CALENDAR_DELETE}>
                      <button
                        onClick={() => {
                          if (!confirmDelete) {
                            setConfirm(true);
                          } else {
                            setConfirm(false);
                            onDelete(event);
                          }
                        }}
                        disabled={loading}
                        type="button"
                        className={`btn btn-${
                          confirmDelete ? "light text-success" : "danger"
                        } btn-sm ms-1`}
                      >
                        <FontAwesomeIcon
                          icon={confirmDelete ? faCheckCircle : faTrash}
                          className={`me-1`}
                        />
                        <small>
                          {t(
                            "common:" + (confirmDelete ? "confirm" : "delete"),
                          )}
                        </small>
                      </button>
                    </Permission>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      </PopoverComponent>
    </>
  );
};

export default PopEvent;
