import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Reducers";
import { DataProviderStates } from "../../store/DataProvider/Types";
import SpinnerComponent from "../Shared/SpinnerComponent";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import {
  faCheck,
  faCheckCircle,
  faPencil,
  faPlus,
  faSave,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTableComponent from "../Shared/CustomTableComponent/CustomTableComponent";
import { actionsDataProvider } from "../../store/DataProvider/Slice";
import { DataProvider, Option, OptionData } from "../../types/DataProvider";
import useIgnoreFirstEffect from "../../hooks/useIgnoreFirstEffect";
import { ProviderType } from "../../types/ProviderType";
import { actionsModal, ModalTypes } from "../../store/Modal/Slice";
import EditString from "../Shared/EditString";
import useTranslate from "../../hooks/useTranslate"; // @ts-ignore
import locale from "react-json-editor-ajrm/locale/en";
import QueryComponent, { Query } from "../Shared/QueryComponent/QueryComponent";
import { routerActions } from "../../store/RouterReducer";
import TabContents from "../Shared/TabContents";
import { Switch } from "antd";
import { IsJsonString } from "../../utils";
import SelectComponent from "../Shared/FieldComponents/SelectComponent";
import useMemoAsync from "../../hooks/useMemoAsync";
import { getAllCloudScript, getCloudScriptDetail } from "../../services/backend/SbxService";
import EditorComponent from "../Shared/EditorComponent/EditorComponent";

const initialNewOpt = {
  label: "",
  value: "",
};

export const parseQueryString = (query: string) => {
  if (IsJsonString(query)) {
    let dp = JSON.parse(query || "{}") as Query | any;

    dp.where = dp.WHERE ?? dp.where ?? [];
    dp.row_model = dp.ROW_MODEL ?? dp.row_model;
    dp.fetch = dp.fetch ?? dp.FETCH;

    if (!dp.row_model) {
      dp = undefined;
    }

    return dp;
  }

  return undefined;
};

export function getQuery(query: string) {
  if (IsJsonString(query)) {
    return parseQueryString(query);
  } else {
    if (query?.includes("${")) {
      const newQuery = checkAndImproveQuery(query);
      if (IsJsonString(newQuery)) {
        return parseQueryString(newQuery);
      }
    }
  }

  return undefined;

  // console.log("dep", dp);
}

function checkAndImproveQuery(jsonString: string): string {
  // Corregir el error en el JSON original
  const json = jsonString.replace(/\${([^}]+)}/g, '"${$1}"');

  // Transformar el JSON corregido
  const jsonObject = JSON.parse(json);
  return JSON.stringify(jsonObject, null, 2);
}

const DetailComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslate("common");
  const { dataProvider, state } = useSelector(
    (state: RootState) => state.DataProviderReducer,
  );
  const [loadingCS, setIsLoadingCS] = useState(false);
  const [newOpt, setNew] = useState(false);
  const [opt, setOpt] = useState<Option>(initialNewOpt);

  //Save new option
  function onSaveOpt(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const option = {
      label: opt.label.trim(),
      value: opt.value.trim(),
    };

    if (opt.id) {
      dispatch(
        actionsDataProvider.deleteOptionFromDataProviderById({
          optionId: opt.id,
          providerId: dataProvider?.id || 0,
          providerType: dataProvider?.provider_type || "",
        }),
      );
    }

    dispatch(
      actionsDataProvider.addOptionToProvider({
        option,
        providerId: dataProvider?.id || 0,
      }),
    );
    setNew(false);
    setOpt(initialNewOpt);
  }

  useEffect(() => {
    if (dataProvider) {
      dispatch(routerActions.changeActive(dataProvider.name));
    }
  }, [dispatch, dataProvider]);

  useIgnoreFirstEffect(() => {
    if (state === DataProviderStates.OPTION_RESOLVED) {
      setNew(false);
    }
  }, [state]);

  useEffect(() => {
    if (state === DataProviderStates.OPTION_DELETED) {
      dispatch(actionsModal.closeModal({ type: ModalTypes.CONFIRM }));
    }
  }, [state]);

  useEffect(() => {
    if (!newOpt) {
      setOpt(initialNewOpt);
    }
  }, [newOpt]);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setOpt({ ...opt, [e.target.name]: e.target.value });
  }

  const onUpdate = (data: OptionData) => {
    setNew((e) => !e);
    setOpt({
      label: data.label,
      value: data.value,
      id: data.id,
    });
  };

  function onDelete(data: OptionData) {
    function onConfirm() {
      dispatch(
        actionsDataProvider.deleteOptionFromDataProviderById({
          optionId: data.id,
          providerId: dataProvider?.id || 0,
          providerType: dataProvider?.provider_type || "",
        }),
      );
    }

    dispatch(
      actionsModal.openModal({
        type: ModalTypes.CONFIRM,
        onConfirm,
        message: (
          <p>
            ¿{`Estas seguro de eliminar la opción `}
            <b>{data.label}</b>?
          </p>
        ),
        title: (
          <span>
            <FontAwesomeIcon className="me-2" icon={faTrash} />
            {t("delete")}
          </span>
        ),
        state: DataProviderStates.OPTION_PENDING,
      }),
    );
  }

  function onUpdateData(data: DataProvider) {
    dispatch(actionsDataProvider.updateDataProvider(data));
  }

  const cloudScriptOptions: { label: string, value: string }[] = useMemoAsync(async() => { 
    setIsLoadingCS(true);
    const res = await getAllCloudScript();
    if(res.items) {
      setIsLoadingCS(false);
      return res.items.map(item => ({ label: item.name, value: item.key }));
    }
    setIsLoadingCS(false);
    return [];
  }, []);

  useEffect(() => {
    if(dataProvider?.provider_type === ProviderType.REST) {
      setOpt(cloudScriptOptions?.find(cloud => cloud.value === JSON.parse(dataProvider?.query || '{}')?._KEY) || initialNewOpt);
    }
  }, [cloudScriptOptions]);

  const csDetail = useMemoAsync(async() => {
    const id = JSON.parse(dataProvider?.query || '{}')?._KEY;
    if(id) {
      const res = await getCloudScriptDetail(id);
      if(res.success) {
        return res.item;
      }
    }
    return '';
  }, [dataProvider]);

  const savingOption = state === DataProviderStates.OPTION_PENDING;
  const loadingProvider = state === DataProviderStates.PROVIDER_PENDING;
  const isSbx =
    dataProvider?.provider_type === ProviderType.SBX ||
    dataProvider?.provider_type === ProviderType.SBX_SEQUENTIAL_QUERY;
  const isCloudScript = dataProvider?.provider_type === ProviderType.REST && JSON.parse(dataProvider?.query || '{}')?.subtype === 'cloudscript';

  switch (true) {
    case !!dataProvider:
      return (
        <>
          <div className="card mb-2">
            <div className="card-body d-flex justify-content-between align-items-center">
              <EditString
                loading={loadingProvider}
                title={dataProvider!.name}
                onEdit={(name: string) =>
                  onUpdateData({ ...dataProvider!, name })
                }
              />
              <div className="text-end">
                {isSbx && (
                  <div className="d-flex gap-3 align-items-center">
                    <Switch
                      checked={
                        dataProvider.provider_type ===
                        ProviderType.SBX_SEQUENTIAL_QUERY
                      }
                      checkedChildren={"SEQUENTIAL_QUERY"}
                      unCheckedChildren={ProviderType.SBX}
                      onChange={(checked) => {
                        onUpdateData({
                          ...dataProvider,
                          provider_type: checked
                            ? ProviderType.SBX_SEQUENTIAL_QUERY
                            : ProviderType.SBX,
                        });
                      }}
                    />
                    <button
                      disabled={loadingProvider}
                      onClick={() => onUpdateData(dataProvider)}
                      className="btn btn-primary btn-sm text-nowrap"
                    >
                      <FontAwesomeIcon
                        spin={loadingProvider}
                        icon={loadingProvider ? faSpinner : faCheck}
                      />
                      {" " + t("save")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Row>
            {isSbx && (
              <Col md={12}>
                <TabContents
                  tabs={[
                    {
                      label: t("principal_query"),
                      component: (
                        <QueryComponent
                          showFetch
                          query={getQuery(dataProvider.query)}
                          getQuery={(q) => {
                            const query = JSON.stringify(
                              typeof q === "function"
                                ? q(dataProvider.query)
                                : q,
                            );

                            dispatch(
                              actionsDataProvider.setDataProvider({
                                ...dataProvider,
                                query,
                              }),
                            );
                          }}
                        />
                      ),
                    },
                    {
                      label: t("default_query"),
                      component: (
                        <QueryComponent
                          showFetch
                          query={getQuery(dataProvider.default_query)}
                          getQuery={(q) => {
                            dispatch(
                              actionsDataProvider.setDataProvider({
                                ...dataProvider,
                                default_query: JSON.stringify(
                                  (typeof q === "function"
                                    ? q(dataProvider.default_query)
                                    : q) as Query,
                                ),
                              }),
                            );
                          }}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
            )}

            {dataProvider!.provider_type === ProviderType.DATABASE && (
              <Col md={12}>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h5>
                    <b>Options:</b>
                  </h5>
                  <Button
                    disabled={savingOption}
                    onClick={() => setNew((e) => !e)}
                    color={newOpt ? "light" : "primary"}
                    size="sm"
                  >
                    <FontAwesomeIcon icon={newOpt ? faTimes : faPlus} />
                    {" " + (newOpt ? t("cancel") : t("add"))}
                  </Button>
                </div>
                {/*To add new option to data provider*/}
                {newOpt && (
                  <form onSubmit={onSaveOpt}>
                    <Row>
                      <Col md={5}>
                        <FormGroup>
                          <Label>Etiqueta:</Label>
                          <input
                            value={opt.label}
                            onChange={onChange}
                            disabled={savingOption}
                            required
                            className="form-control"
                            type="text"
                            name="label"
                            placeholder="ej: M"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={5}>
                        <FormGroup>
                          <Label>Valor:</Label>
                          <input
                            value={opt.value}
                            onChange={onChange}
                            disabled={savingOption}
                            required
                            className="form-control form-control"
                            type="text"
                            name="value"
                            placeholder="ej: Masculino"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label>&nbsp;</Label>
                          <br />
                          <button className="btn btn-primary btn-sm mt-2">
                            <FontAwesomeIcon icon={faSave} />
                            {" " + t("save")}
                          </button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                )}
                {/*Table from current options*/}
                <CustomTableComponent
                  data={dataProvider!.options}
                  actions={[
                    {
                      type: "info",
                      label: <FontAwesomeIcon icon={faPencil} />,
                      title: t("update"),
                      onAction: onUpdate,
                    },
                    {
                      type: "danger",
                      label: <FontAwesomeIcon icon={faTrash} />,
                      title: t("delete"),
                      onAction: onDelete,
                    },
                  ]}
                  columns={[
                    { name: "label", header: "Etiqueta", type: "String" },
                    { name: "value", header: "Valor", type: "String" },
                  ]}
                />
              </Col>
            )}

            {
              isCloudScript && (
                <>
                <Col md={12}>
                  <div className="card">
                    <div className="card-body">
                      <FormGroup>
                        <Label>Select a CloudScript</Label>
                        <div style={{ position: 'relative', zIndex: 5 }}>
                          <SelectComponent
                            loading={loadingCS}
                            options={cloudScriptOptions}
                            value={opt}
                            onChange={(e) => {
                              setOpt(e);
                            }}
                            name="cloudscript"
                            id="cloudScriptSelect"
                          />
                        </div>
                        {
                          (JSON.parse(dataProvider?.query || '{}')?._KEY !== opt.value && opt.value) && (
                            <div className="d-flex justify-content-end mt-2">
                              <button
                                type={"button"}
                                onClick={() => {
                                  const value_string = JSON.stringify({ subtype: 'cloudscript', _KEY: opt.value });
                                  onUpdateData({ ...dataProvider!, query: value_string });
                                }}
                                className="btn btn-primary btn-sm"
                              >
                                {<FontAwesomeIcon icon={faCheckCircle} className="me-1" />}{" "}
                                {" " + t("save")}
                              </button>
                              <button
                                type="button"
                                onClick={() => setOpt(cloudScriptOptions?.find(cloud => cloud.value === JSON.parse(dataProvider?.query || '{}')?._KEY) || initialNewOpt)}
                                className="btn btn-light  btn-sm shadow-sm"
                              >
                                {<FontAwesomeIcon icon={faTimesCircle} className="me-1" />}
                                {" " + t("cancel")}
                              </button>
                            </div>
                          )
                        }
                      </FormGroup>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="card mt-2">
                    <div className="card-body">
                      <FormGroup>
                        <Label>CloudScript Preview</Label>
                        <EditorComponent
                          mode="json"
                          readOnly
                          value={csDetail}
                          width="100%"
                          height="500px"
                          name="UNIQUE_ID_OF_DIV"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </Col>
                </>
              )
            }
          </Row>
        </>
      );

    case state === DataProviderStates.DATA_RESOLVED:
    default:
      return (
        <div className="d-flex justify-content-center">
          <SpinnerComponent />
        </div>
      );
  }
};

export default DetailComponent;
