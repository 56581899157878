// DocumentSbxTableComponent.tsx
import React, {useEffect, useRef, useState} from 'react';
import {downloadFileService, getFile, getFilesDataByKeys} from '../../../services/UtilsService';
import {FileTypeIcon} from '../ContentEngine';
import {getContentFileName} from '../../../utils';
import {Content} from '../../../types/Folder/Content';
import useTranslate from '../../../hooks/useTranslate';
import {RequestQueue} from "../../../classes/RequestQueue";

const requestQueue = new RequestQueue(2);

type Props = {
    docKeys: string[];
};

const cacheDocument: {[key: string]: Content[]} = {};

const DocumentSbxTableComponent = ({docKeys}: Props) => {
    const {t} = useTranslate('common');
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState<Content[]>([]);
    const [, setError] = useState(null);
    const [pendingRequest, setPendingRequest] = useState(requestQueue.hasPendingRequests())
    const onMount = useRef(false)

    useEffect(() => {
        const id = docKeys.join(',');
        onMount.current = true
        if (cacheDocument[id]) {
            setDocs(cacheDocument[id]);
            setLoading(false);
        }else{

            if (!pendingRequest){
                setLoading(true);
                const requestFn = () => getFilesDataByKeys(docKeys);
                requestQueue.addRequest(id, requestFn)
                    .then((response) => {
                        cacheDocument[id] = response.item.contents;
                        setPendingRequest(requestQueue.hasPendingRequests());
                        setDocs(response.item.contents);
                        setLoading(false);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            }


        }

        return () =>{
        }
    }, [docKeys]);

    if (loading) {
        return (
            <p>
                {t('loading')} {t('files')}...
            </p>
        );
    }

    async function onConfirmDownload(fileData: Content) {
        const res: any = await getFile(fileData.key);
        if (res.success) {
            await downloadFileService(res.url, res.name);
        }
    }

    return (
        <div>
            {docs.length > 0 ? (
                <div className="d-flex flex-column gap-2">
                    {docs.map((document, index) => (
                        <span
                            key={document.key + '_' + index}
                            onClick={() => (document.name ? onConfirmDownload(document) : null)}
                            className="underline d-flex align-items-center gap-2 pointer"
                        >
              {document.name ? (
                  <>
                      <FileTypeIcon name={document.name}/> {getContentFileName(document)}
                  </>
              ) : (
                  t('no_file_found')
              )}
            </span>
                    ))}
                </div>
            ) : (
                <p>{t('no_documents_found')}</p>
            )}
        </div>
    );
};

export default DocumentSbxTableComponent;
