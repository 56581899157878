import * as React from 'react';
import {useMemo, useState} from 'react';
import {faRocket} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "./ButtonComponent";
import {cloudScriptRun} from "../../services/backend/SbxService";
import {actionsModal, ModalTypes} from "../../store/Modal/Slice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import useTranslate from "../../hooks/useTranslate";
import {authReducer} from "../../store/Selectors";
import {toast} from "../../utils";
import {UserData} from "../../types/User";

type Props = {
    csKey: string
    row: any
    cloudscripts?: { name: string, key: string }[]
};


const CloudscriptButtonComponent = ({csKey, row, cloudscripts}: Props) => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const {user} = useSelector(authReducer)
    const {t} = useTranslate("common")

    const cloudscript = useMemo(() => cloudscripts?.find((item) => item.key === csKey), [cloudscripts])


    return (

        <ButtonComponent outline style={{
            width: '100%'
        }} label={cloudscript?.name ? cloudscript?.name.split("_").join(" ") : ""}
                         loading={loading} disabled={loading}
                         className="justify-content-center"
                         onClick={async () => {
                             const newUser: Partial<UserData> = {...user}

                             delete newUser.permissions
                             delete newUser.groups
                             delete newUser.config
                             delete newUser.permissionsMapper
                             delete newUser.domains


                             dispatch(actionsModal.openModal({
                                 closeModal: true,
                                 type: ModalTypes.CONFIRM,
                                 onConfirm: async () => {
                                     setLoading(true)


                                     const response: {
                                         message?: string
                                         show_message?: string
                                         redirect?: string
                                     } = await cloudScriptRun({
                                         key: csKey,
                                         params: {
                                             current_user: newUser,
                                             data: row
                                         }
                                     })


                                     if (response?.show_message && response?.message) {
                                         toast({message: response.message ?? ""});
                                     }

                                     if (response.redirect) {
                                         window.location.href = response.redirect
                                     }

                                     setLoading(false)
                                 },
                                 message: <>{
                                     `${t("custom-message:warn-execute")} ${t("custom-message:this_action")}?`
                                 }</>,
                                 title: <><FontAwesomeIcon
                                     icon={faRocket}/> {' ' + t('execute')}</>,
                             }));


                         }} icon={faRocket}/>
    );
};

export default CloudscriptButtonComponent