import {Field} from "../../../types/Field";
import {Control, Controller, UseFormRegister} from "react-hook-form";
import {SubType} from "../../../types/FieldType";
import NumberComponent from "../../Shared/FieldComponents/NumberComponent";
import React, {useContext, useEffect, useState} from "react";
import TimeComponent from "../../Shared/FieldComponents/TimeComponent";
import EmailComponent from "../../Shared/FieldComponents/EmailComponent";
import DocumentComponent from "../../Shared/FieldComponents/DocumentComponent";
import PhoneComponent from "../../Shared/FieldComponents/PhoneComponent";
import {CrmInputContext, dateDefault} from "./CrmRenderInput";
import {TaskComponentContext} from "../../../context/TaskContext";
import CurrencyComponent from "../../Shared/FieldComponents/CurrencyComponent";
import useTranslate from "../../../hooks/useTranslate";
import {State} from "../../../types/State";
import {getVariableDefaultValue} from "../../../utils";

type Props = {
    field: Field,
    control?: Control<any, object>
    register?: UseFormRegister<any>
    inputContext?: React.Context<any>
};

const CrmSmallTextComponent = ({field, control, register, inputContext}: Props) => {
    const {t} = useTranslate("common")

    const [defaultValue, setDefaultValue] = useState<string>('');
    const {getDefaultValue} = useContext(inputContext ?? CrmInputContext)
    const {watch, setValue, getFormValue, setLoadingItems, loadingItems, task} = useContext(TaskComponentContext);

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let subscription: any = null
        if (watch) {
            subscription = watch((obj, {name, type}) => {

                if (setLoadingItems && (field.read_only || field.default_value) && name === field.name && !dateDefault.includes(field.default_value) && !field.format_rules_definition?.hide) {

                    setLoadingItems(State.PENDING)
                }


                setTimeout(() => {

                    const value = getDefaultValue(field)
                    let defaultValue = field.default_value ? getVariableDefaultValue(field.default_value) : "";
                    const processDataValue = task?.process_data ? task?.process_data![field.name] ?? defaultValue ? task?.process_data[defaultValue] : undefined: undefined

                    let nValue = value?.name ? value?.value ?? "" : value ?? ""

                    if (processDataValue?.type && processDataValue?.type === "STRING" && nValue){
                        nValue = nValue.toString()
                    }

                    setDefaultValue(nValue)

                    if (!value || value === "" || value !== 0){
                        if (setLoadingItems) {
                            setLoadingItems(State.RESOLVED)
                        }
                    }

                }, 1000)


            });
        }
        return () => subscription?.unsubscribe();
    }, [watch, field]);

    React.useEffect(() => {
        let defaultValueVar = field.default_value ? getVariableDefaultValue(field.default_value) : "";
        const processDataValue = task?.process_data ? task?.process_data![field.name] ?? defaultValue ? task?.process_data[defaultValueVar] : undefined : undefined
        if (!dateDefault.includes(field.default_value)){
            if((field.read_only || field.default_value ) && !dateDefault.includes(field.default_value)){
                setLoading(true)
            }

            if ((!defaultValue || defaultValue === "") && setValue) {

                setValue(field.name, undefined)
                if (setLoadingItems) {
                    setLoadingItems(State.RESOLVED)
                }
                // setLoading(false)
            } else {
                if (defaultValue && setValue && getFormValue) {

                    // setLoading(true)
                    if (!getFormValue(field.name)) {
                        let nValue = defaultValue

                        if (processDataValue?.type && processDataValue?.type === "STRING" && nValue){
                            nValue = nValue.toString()
                        }
                        setValue(field.name, nValue)
                    } else {
                        if (getFormValue(field.name) !== defaultValue) {
                            let nValue = defaultValue
                            if (processDataValue?.type && processDataValue?.type === "STRING" && nValue){
                                nValue = nValue.toString()
                            }
                            setValue(field.name, nValue)
                        }
                    }

                    setTimeout(() => {
                        // setLoading(false)
                        if (setLoadingItems) {
                            setLoadingItems(State.RESOLVED)
                        }
                    }, 500)
                } else {
                    if (setLoadingItems) {
                        setLoadingItems(State.RESOLVED)
                    }
                    // setLoading(false)

                }
            }

        }


    }, [defaultValue, field]);

    React.useEffect(() => {

        if  (loading){
            setTimeout(() => {
                setLoading(false)
            }, 1200)
        }

    }, [defaultValue, loading]);

    React.useEffect(() => {
        setTimeout(()=>{
            if (setLoadingItems) {
                setLoadingItems(State.RESOLVED)
            }
        }, 500)
    }, [defaultValue, loadingItems]);


    const getField = () => {

        if (register) {


            const defaultInput = <input className="form-control"
                                        id={field.name + '_' + (field.id ?? field.sort_index.toString() + "_" + field.name)}
                                        key={field.name + '_' + (field.id ?? field.sort_index.toString() + "_" + field.name)}
                                        disabled={field.read_only}
                                        type="text"
                                        defaultValue={defaultValue}
                                        {...register(field.name, {required: field.required && !field.read_only})}
            >
            </input>;

            if (!field.sub_type) {
                return defaultInput
            }


            let input: JSX.Element | null = null

            switch (field.sub_type) {
                case SubType.NUMBER: {
                    input = <NumberComponent
                        id={field.id.toString() ?? field.sort_index.toString() + "_" + field.name}
                        disabled={field.read_only}
                        key={field.id.toString() ?? field.sort_index.toString() + "_" + field.name}
                        defaultValue={defaultValue}
                        register={register(field.name, {
                            required: field.required && !field.read_only,
                        })}
                        placeholder={field.placeholder ?? ''}
                        name={field.name}/>
                }
                    break;

                case SubType.TIME: {
                    input = <TimeComponent id={field.id.toString() ?? field.sort_index.toString() + "_" + field.name}
                                           disabled={field.read_only}
                                           defaultValue={defaultValue}
                                           register={register(field.name, {
                                               required: field.required && !field.read_only,
                                           })}
                                           placeholder={field.placeholder ?? ''}
                                           name={field.name}/>
                }
                    break;

                case SubType.EMAIL: {
                    input = <EmailComponent
                        id={field.id.toString() ?? field.sort_index.toString() + "_" + field.name}
                        key={field.id.toString() ?? field.sort_index.toString() + "_" + field.name}
                        disabled={field.read_only}
                        defaultValue={defaultValue}
                        register={register(field.name, {
                            required: field.required && !field.read_only,
                            pattern: field.format_rules_definition?.hide ? undefined : /^\S+@\S+$/i,
                        })}
                        placeholder={field.placeholder ?? ''}
                        name={field.name}/>
                }
                    break;

                case SubType.CURRENCY: {
                    input = <Controller
                        control={control}
                        name={field.name}
                        defaultValue={defaultValue ?? undefined}
                        rules={{
                            required: field.required && !field.read_only
                        }}
                        render={({field: {onChange, name, value}}) => (
                            <CurrencyComponent
                                id={field.name + '_' + (field.id ?? field.sort_index.toString() + "_" + field.name)}
                                value={value}
                                disabled={field.read_only}
                                defaultValue={defaultValue ?? ''}
                                onChange={onChange}
                                placeholder={field.placeholder ?? ''}
                                name={name}/>
                        )}/>
                }

                    break
                case SubType.DOCUMENT: {
                    input = <Controller
                        control={control}
                        name={field.name}
                        rules={{
                            required: field.required && !field.read_only,
                            pattern: /^[0-9]{6,15}/i
                        }}
                        render={({field: {onChange, name, value}}) => (
                            <DocumentComponent
                                id={field.name + '_' + (field.id ?? field.sort_index.toString() + "_" + field.name)}
                                key={field.name + '_' + (field.id ?? field.sort_index.toString() + "_" + field.name)}
                                value={value}
                                disabled={field.read_only}
                                defaultValue={defaultValue ?? getDefaultValue(field) ?? ''}
                                onChange={onChange}
                                readonly={field.read_only}
                                placeholder={field.placeholder ?? ''}
                                name={name}/>
                        )}/>
                }
                    break;

                case SubType.PHONE: {
                    input = <Controller
                        control={control}
                        name={field.name}
                        rules={{
                            required: field.required && !field.read_only,
                            pattern: /^[0-9]{6,15}/i
                        }}
                        render={({field: {onChange, name, value, ref}}) => (
                            <PhoneComponent
                                id={field.name + '_' + (field.id ?? field.sort_index.toString() + "_" + field.name)}
                                key={field.name + '_' + (field.id ?? field.sort_index.toString() + "_" + field.name)}
                                value={value}
                                indicative={field.format_rules_definition?.indicative ?? ''}
                                hideIndicative={field.format_rules_definition?.hide_indicative ?? false}
                                disabled={field.read_only}
                                defaultValue={defaultValue ?? getDefaultValue(field) ?? ''}
                                onChange={onChange}
                                placeholder={field.placeholder ?? ''}
                                name={name}/>
                        )}
                    />
                }

                    break;
            }

            return input ?? defaultInput
        }

        return null

    }

    return loading ? <input type="text" disabled className="form-control" value={t('loading')}/> : getField()
};

export default CrmSmallTextComponent