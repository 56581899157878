import * as React from 'react';
import {Fragment, useEffect, useRef, useState} from 'react';
import {Column} from "../CustomTableComponent/CustomTableComponent";
import {Popover} from "antd";
import {faCircleMinus, faCirclePlus, faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../../hooks/useTranslate";
import ButtonComponent from "../ButtonComponent";
import {ModelsResponse} from "../../../types/Sbx";
import {SubMenuType} from "antd/es/menu/hooks/useItems";
import {capitalize} from "../../../utils";
import {Find} from "sbxcorejs";
import {reportPropType} from "../../ReportsComponent/ReportComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {usePopper} from "react-popper";
import TableColumnsListChildren from "./TableColumnsListChildren";

type Props = {
    column: Column
    columnItems: any[]
    sbxModels?: ModelsResponse[],
    updateColumnsProps?: ({reportProp, column_name, value, subProp}: {
        column_name: string,
        value: any, subProp?: string,
        reportProp: reportPropType
    }) => void;
};

export interface MenuProps {
    items: Item[];
}

export interface Item {
    key: string;
    type?: string;
    label: string | React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    children?: Item[];
}


const specialCharacters = ['$', '%', ',', '.']
const TableCustomColumnMenuComponent = ({

                                            column,
                                            columnItems,
                                            updateColumnsProps,
                                            sbxModels
                                        }: Props) => {


    const {t} = useTranslate('common');
    const [disableNumberOptions, setDisableNumberOptions] = useState(false)
    const linkRef = useRef<null | HTMLInputElement>(null)
    const linkContainerRef = useRef<null | HTMLDivElement>(null)

    const [openDropdown, setOpenDropdown] = useState(false);
    const [openItems, setOpenItems] = useState<string[]>([]);


    const toggleDropdown = () => {
        setOpenDropdown(!openDropdown);
        setOpenItems([])
    };

    const toggleItem = (key: string, isChild = false) => {
        if (isChild){
            setOpenItems(prevItems => prevItems.some(prevKey => prevKey === key) ? prevItems.filter(prevKey => prevKey !== key) : ([...prevItems, key]))
        }else{
            setOpenItems([key])
        }

    };


    React.useEffect(() => {
        setDisableNumberOptions(columnItems.filter(element => element).every(element => typeof element === 'string' && !specialCharacters.some(character => element.includes(character))))
    }, [columnItems]);

    React.useEffect(() => {
        if (column?.value && linkRef.current) {
            linkRef.current!.value = column.value
        }
    }, [column]);

    const updateColumnProp = ({value, reportProp, subProp, toggle = true}: {
        subProp?: string
        value: any,
        reportProp: reportPropType,
        toggle?: boolean
    }) => {
        if (toggle) {
            toggleDropdown()
        }

        if (updateColumnsProps && reportProp) {
            updateColumnsProps({column_name: column.name, value, reportProp, subProp})
        }
    }

    const items = (t: (str: string) => string) => {


        let colorList: string[] = []

        let options = [
            {
                key: '1',
                type: 'group',
                label: t("options"),
                children: [
                    {
                        key: '1-1',
                        disabled: disableNumberOptions,
                        label: `${column.isTotalColumn ? t("remove") : t("add")} ${t("report:total_per_column")}`,
                        onClick: () => {
                            updateColumnProp({
                                value: !column.isTotalColumn,
                                reportProp: "columns_to_summarize"
                            })
                        }
                    },
                    {
                        key: '1-2',
                        disabled: disableNumberOptions || column.name === "total_column_by_row",
                        label: `${column.metadata_type?.includes("sum_rows") ? t("remove") : t("add")} ${t("report:total_per_row")}`,
                        onClick: () => {
                            updateColumnProp({
                                value: !column.metadata_type?.includes("sum_rows"),
                                subProp: "sum_rows",
                                reportProp: "columns_metadata"
                            })
                        }
                    },
                    {
                        key: '1-3',
                        disabled: disableNumberOptions,
                        label: `${column.metadata_type?.includes("average") ? t("remove") : ""} ${t("average_column")}`,
                        onClick: () => {
                            updateColumnProp({
                                value: !column.metadata_type?.includes("average"),
                                subProp: "average",
                                reportProp: "columns_metadata"
                            })
                        }
                    },
                    {
                        key: '1-4',
                        disabled: !sbxModels,
                        label: <Popover placement="right" title={""} content={

                            <div className="p-2" ref={linkContainerRef} style={{width: '20em'}} id={"form_link"}>
                                <div className="d-flex align-items-center">
                                    <span>Link: </span>
                                    <ButtonComponent label={""} color={"default"} icon={faCirclePlus} iconColor={"blue"}
                                                     onClick={() => {
                                                         if (linkRef.current) {
                                                             linkRef.current.value += "{row_value}"
                                                         }
                                                     }} tooltipLabel={t("custom-message:make_click_add_row_value")}/>
                                </div>

                                <div className="d-flex gap-2 align-items-center">
                                    <input type="text" ref={linkRef} className="form-control"
                                           defaultValue={linkRef.current!?.value || ""}
                                           placeholder={"www.mylink.com"}/>
                                    <ButtonComponent label={""} outline className="p-2" onClick={() => {
                                        updateColumnProp({

                                            value: linkRef.current?.value,
                                            subProp: "Link",
                                            reportProp: "custom_column"
                                        })

                                        if (linkRef.current) {
                                            linkRef.current.value = ""
                                        }
                                    }} icon={faCirclePlus}/>

                                    {column.value &&
                                        <ButtonComponent label={""} outline className="p-2" color={"danger"}
                                                         onClick={() => {
                                                             linkRef.current!.value = ""
                                                             updateColumnProp({

                                                                 value: "",
                                                                 reportProp: "custom_column"
                                                             })
                                                         }} icon={faCircleMinus}/>}
                                </div>
                            </div>} trigger="hover">

                            <div>{`${t("add")} ${t("link")}`}</div>
                        </Popover>
                    },
                    {
                        key: "1-5",
                        label: `${t("remove")} ${t("format")}`,
                        disabled: !column.type,
                        onClick: () => {
                            updateColumnProp({value: "", reportProp: "custom_column"})
                        }
                    }
                ],
            },
            {
                key: '2',
                // type: 'group',
                label: `${t("associate")} ${t("to_1")} ${t("sbx_model")}`,
                children: []
            },
            {
                key: '3',
                label: `${t("custom_format")}`,
                children: [
                    {
                        key: "file",
                        label: t("file"),
                        onClick: () => {
                            updateColumnProp({value: "Document", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "image",
                        label: t("image"),
                        onClick: () => {
                            updateColumnProp({value: "Image", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "cloudscript",
                        label: t("cloudscript"),
                        onClick: () => {
                            updateColumnProp({value: "cloudscript", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "remove_3",
                        label: `${t("remove")} ${t("format")}`,
                        onClick: () => {
                            updateColumnProp({value: "", reportProp: "custom_column"})
                        }
                    }
                ],
            },
            {
                key: '4',
                label: `${t("custom_format")} ${t("report:numeric")}`,
                disabled: disableNumberOptions,
                children: [
                    {
                        key: "money_comma_decimal",
                        label: "$32,000",
                        onClick: () => {
                            updateColumnProp({value: "money_comma_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "comma_decimal",
                        label: "32,000,02",
                        onClick: () => {
                            updateColumnProp({value: "comma_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "money_dot_decimal",
                        label: "$12.000,00",
                        onClick: () => {
                            updateColumnProp({value: "money_dot_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "two_decimal",
                        label: "1,234.57",
                        onClick: () => {
                            updateColumnProp({value: "two_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "dot_no_decimal",
                        label: "32.000",
                        onClick: () => {
                            updateColumnProp({value: "dot_no_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "comma_no_decimal",
                        label: "32,000",
                        onClick: () => {
                            updateColumnProp({value: "comma_no_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "percentage",
                        label: "32%",
                        onClick: () => {
                            updateColumnProp({value: "percentage", reportProp: "custom_column"})
                        }
                    }
                    ,
                    {
                        key: "remove_4",
                        label: `${t("remove")} ${t("format")}`,
                        onClick: () => {
                            updateColumnProp({value: "", reportProp: "custom_column"})
                        }
                    }
                ],
            },
            {
                key: '5',
                label: `${t("custom_format")} ${t("text")}`,
                children: [
                    {
                        key: "document",
                        label: t("document"),
                        disabled: !disableNumberOptions,
                        onClick: () => {
                            updateColumnProp({value: "Document", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "color",
                        label: <Popover placement="right" title={""} content={
                            <div style={{width: '10em'}}>
                                <input type="color" className="form-control" defaultValue={column.value || ""}
                                       onChange={(event) => {
                                           let color = event.target.value
                                           colorList.unshift(color)
                                           setTimeout(() => {

                                               if (colorList.length > 0) {
                                                   updateColumnProp({

                                                       subProp: "TextColor",
                                                       value: colorList[0],
                                                       toggle: false,
                                                       reportProp: "custom_column"
                                                   })
                                                   colorList = []
                                               }

                                           }, 1000)
                                       }}/>
                            </div>
                        }>
                            <div>{`${t("report:select")} ${t("color")}`}</div>
                        </Popover>,
                    },
                    {
                        key: "remove-text",
                        label: `${t("remove")} ${t("format")}`,
                        disabled: !column.value,
                        onClick: () => {
                            updateColumnProp({value: "", reportProp: "custom_column"})
                        }
                    }
                ],
            }
        ]

        if (sbxModels && sbxModels!?.length > 0 && options) {

            (options[1] as SubMenuType).children = sbxModels.map(sbxModel => {
                return {
                    key: sbxModel.id,
                    label: capitalize(sbxModel.name),
                    children: [{
                        name: "_KEY",
                        id: "0" + sbxModel.name
                    }, ...sbxModel.properties].map((property, index) => {

                        const query = new Find(sbxModel.name, 0)
                        query.andWhereIsEqualTo(property.name, "{row_value}")
                        const compiledQuery = query.compile()

                        const params = encodeURI(JSON.stringify(compiledQuery.where))

                        const queryUrl = `https://sbxcloud.com/#!/app/data/129/model/${sbxModel.id}/list?qr=${params}`


                        return {
                            key: property.id,
                            label: capitalize(property.name),
                            onClick: () => {
                                updateColumnProp({

                                    value: queryUrl,
                                    subProp: "Link",
                                    reportProp: "custom_column"
                                })
                            }
                        }
                    })
                }
            })

            if ((options[1] as SubMenuType)?.children && column.value) {
                (options[1] as SubMenuType).children.unshift({
                    key: "remove_association",
                    label: `${t("remove")} ${t("association")}`,
                    onClick: () => {
                        updateColumnProp({

                            value: "",
                            reportProp: "custom_column"
                        })
                    }
                })
            }
        } else {
            if (sbxModels?.length === 0 && options) {
                options = options.filter(option => option?.key !== '2')
            }
        }


        return options

    }

    const renderMenuItem = ({item, isChild = false}:{item: Item, isChild?: boolean}) => {


        const isOpen = openItems.includes(item.key)


        return <>
            <TableColumnsListChildren item={item} isChild={isChild} isOpen={isOpen} renderMenuItem={renderMenuItem} toggleItem={toggleItem}/>

            {item.type === "group" ? item.children!.map((child) => (
                <li key={child.key} onClick={() => {
                    if (!child.disabled && child.onClick) {
                        child.onClick()
                    }

                }} className={`list-group-item shading pointer ${child.disabled ? "text-gray" : ""}`} style={{paddingLeft: "30px"}}>
                    {child.label}
                </li>
            )) : null}
        </>
    };


    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
    const [popperElement, setPopperElement] = useState<any | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'auto',
        strategy: "fixed"
    });

    const dropdownRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) ) {

                if (linkContainerRef.current && linkContainerRef.current.contains(event.target as Node)) {
                }else{
                    setOpenDropdown(false)
                    setOpenItems([])
                }


            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    return (
        <div className={`dropdown ${openDropdown ? 'show' : ''}`} ref={dropdownRef}>
            <button  className={`pointer btn`} style={{zIndex: 1}} onClick={toggleDropdown}  ref={setReferenceElement}>
                <FontAwesomeIcon icon={faEllipsisV}/>
            </button>
            {openDropdown && <div ref={setPopperElement}
                                    style={{...styles.popper, zIndex: 9999}}
                                    {...attributes.popper}> <ul  className={`list-group-flush menu ${openDropdown ? 'show shadow' : ''}`} >
                {items(t).map((item) => {
                    return <Fragment key={"menuItem_" + item.key}>
                        {renderMenuItem({item})}
                    </Fragment>
                })}
            </ul></div>}
        </div>
    );
};

export default TableCustomColumnMenuComponent